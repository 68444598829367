import React from 'react'

const Uploadlogomodal = ({ closemodal }) => {
    return (
        <>
            <div className='uploabannermodalbgshadow' >
                <div className='uploalogomodalbg'>
                    <div className='crossdivicon' onClick={() => closemodal()}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div className='upldbantrtupldbannerdiv'>
                        <h4 className='upldbannertrtxtpp'>Upload Profil Picture</h4>
                        <p className='selletxtcbabbtxttpp'>
                            Select and upload the files of your choice
                        </p>
                    </div>

                    <div className='uploadlogobroderrardtdivbtt'>
                        <div className='uploadlogobroderrardt'>
                            <div className='filedibbhsjj'>
                                <input type='file' className='filedibbhsjjdiflediv' />
                            </div>
                            <button className='broowstsffibthnsttdiv'>Browse file</button>
                        </div>
                        <div className='chooststtsrrtxtppfxtppdiv'>
                            <h4 className='choosttflttxtpp'>Choose a file upload it here</h4>
                            <p style={{textAlign:"center"}}>Pixel Size: 140 x 140 PNG/JPEG</p>
                            <p className='jjshpsttsrrtxtpp'>JPEG, PNG formats, up to 1 MB</p>
                        </div>
                        <div className='svavtatvvbtnnmdivmaokin'>
                            <button className='svavtatvvbtnnm'>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Uploadlogomodal
