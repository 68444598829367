import { combineReducers } from 'redux';
import PostSlice from '../Slice/PostSlice';
import Offer from "../Slice/Offer";
import Chat from "../Slice/Chat";
import BusinessPostSlice from "../Slice/BusinessPostSlice"
import NotificationSlice from "../Slice/NotificationSlice"
import EnquirySlice from "../Slice/EnquirySlice"

const rootReducer = combineReducers({
    app: PostSlice,
    offer: Offer,
    chat: Chat,
    post: BusinessPostSlice,
    notification: NotificationSlice,
    enquiry: EnquirySlice,
});

export default rootReducer;
