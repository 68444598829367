import React, { useState, useEffect } from "react";
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import HttpClient from "../utils/HttpClient";

const Editgalleryproductmodal = ({
  closemodal,
  selectedImage,
  setSelectedImage,
  data,
  updateForm,
  setData,
  selectedIndex,
}) => {
  const [title, setTitle] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(selectedImage?.image ?? "");
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    if (selectedImage) {
      setTitle(selectedImage.title || "");
      setShortDesc(selectedImage.shortDesc || "");
      setUrl(selectedImage.image || "");
    }
  }, [selectedImage]);

  

  const handleDeleteImage = (id) => {
    setData((prevData) => ({
      ...prevData,
      gallery: prevData.gallery.filter(
        (item,index) => index !== selectedIndex
      ),
    }));
    closemodal(); // Close modal after delete
  }

  const _handleDeleteImage = (id) => {
    setData((prevData) => ({
      ...prevData,
      gallery: prevData.gallery.filter(
        (item) => item._id !== id
      ),
    }));
    closemodal(); // Close modal after delete
  }

  const handleUploadPic = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setFile(selectedFile);
      setUrl(fileURL); // Set preview image
    }
    setLoading(true);
    setImageError(""); // Reset error message on save

    try {
      if (file) {
        const formData = new FormData();
        formData.append("image", file);

        const res = await HttpClient.fileUplode(
          "image-upload",
          "POST",
          formData
        );
        console.log("Upload response:", res); // Log the response

        if (res.status) {
          setUrl(res?.data?.url); // Update with the uploaded image URL
          // // Close the modal
        } else {
          throw new Error("Error while uploading image");
        }
      }
    } catch (error) {
      console.error("Upload failed:", error);
      setImageError(error.message || "Upload failed");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const submit = async () => {
    console.log("LINE65", data, selectedImage, selectedIndex, url, title, shortDesc);
    // return
    if (selectedImage) {
      // Update the gallery item in data
      setData((prevData) => ({
        ...prevData,
        gallery: prevData.gallery.map((item, index) =>
          // item._id === selectedImage._id ||
            index === selectedIndex
            ? {
              ...item,
              title: title,
              shortDesc: shortDesc,
              image: url,
            }
            : item
        ),
      }));



      closemodal(); // Close modal after save
    }
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="editramoffahsgaellrttdivshhafb">
        <div className="editabannerideletcrrsdivhjdsj">
          <div className="diesdrddeytecroosdivffzl">
            <div className="dettetstyyiocnnhui" onClick={closemodal}>
              <img src={crosssimgiocn} alt="Close modal" />
            </div>
          </div>
          <div>
            <p className="edittsbannerttxtppp">Edit Gallery Image</p>
          </div>
        </div>

        <div className="galleytyusysdheheiighgsccgyyukj">
          <div className="hahgetytsiimghbmaindibv">
            {loading ? (
              <div className="loader-container">
                <div
                  className="loader "
                  style={{
                    position: "absolute",
                    top: " 50%",
                    left: "50%",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <i className="fa-solid fa-trash delete-icon"
                  onClick={() => handleDeleteImage(selectedImage?._id)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "absolute",
                    right: "10px",
                    marginTop: "10px",
                    padding: "10px",
                    background: "grey",
                    borderRadius: "50%",
                  }}
                ></i>

                <img src={url} alt="Selected" />
                <button className="bggsttgaggsllerybanutn">
                  Browse File
                  <input
                    type="file"
                    className="ijnbnsdkpwggartyiomghghs"
                    onChange={handleUploadPic}
                    aria-label="Upload new image"
                    disabled={loading} // Disable file input while loading
                  />
                </button>
              </>
            )}
          </div>

          <div className="hhsjjhahhahuyguuydibbanmnib">
            <div className="hhsjjhahhahuyguuy">
              <p className="galrrttxtittxttxtpp">Title</p>
              <input
                value={title}
                type="text"
                className="insttsbtrxrrds"
                onChange={(e) => setTitle(e.target.value)}
                aria-label="Image title"
                disabled={loading} // Disable input while loading
              />
            </div>

            <div className="hhsjjhahhahuyguuy">
              <p className="galrrttxtittxttxtpp">Description</p>
              <textarea
                value={shortDesc}
                rows={4}
                className="insttsbtrxrrdstexrare"
                onChange={(e) => setShortDesc(e.target.value)}
                aria-label="Image description"
                disabled={loading} // Disable textarea while loading
              />
            </div>
            {imageError && <p className="error-message">{imageError}</p>}
            <div className="svavtatvvbtnnmdivmaokin">
              <button
                className="svavtatvvbtnnm"
                disabled={loading}
                onClick={submit}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Editgalleryproductmodal;
