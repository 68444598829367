import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import cntnctimg3 from "../../Images/cntnctimg3.png";
import cmtprofiletwoimg from "../../Images/cmtprofiletwoimg.png"
import { commentAdd } from '../../Redux/Slice/PostSlice';
import { formatDateAgo } from '../../utils/Helper';
import { LoadBusinessPostsInBackground } from '../../Redux/Slice/BusinessPostSlice';


const PostComments = ({ data, postProfileId, postId }) => {
    const dispatch = useDispatch()
    const { UserProfileID } = useSelector((state) => state.app);


    const [allComments, setAllComments] = useState([])

    useEffect(() => {
        
        setAllComments(data)
    }, [])
    console.log("DATA17", allComments);

    const [commentText, setCommentText] = useState("")

    const handleCommentSend = (e) => {
        e.preventDefault()
        const data = {
            postId: postId,
            comment: commentText
        }

        console.log("DATA30", data);
        dispatch(commentAdd(data))
        dispatch(LoadBusinessPostsInBackground())

        setCommentText("")
        setAllComments((prevComments) => [
            { comment: commentText, postId: postId, commentedBy: [{ profilePhoto: UserProfileID?.profilePhoto, personName: UserProfileID?.personName }] },
            ...prevComments
        ]);
    }

    return (
        <>
            <div className='ownpostcommentdiv'>
                {
                    UserProfileID._id !== postProfileId &&
                    <div className='ownpostcommentdivmain'>
                        <div className="cntnctimgdivnamin">
                            <img src={UserProfileID?.profilePhoto || cntnctimg3} alt={UserProfileID?.personName || "..."} />
                        </div>

                        <div className='textareadibmainmainbrdr'>
                            <div className='textareadibmain'>
                                <textarea rows={4} cols={4} className='textareadibmainrerare'
                                    placeholder='Write a Comment'
                                    onChange={(e) => setCommentText(e.target.value)}
                                    value={commentText || ""}
                                />
                            </div>
                            <div className="mesagsendidvmain"
                                onClick={(e) => handleCommentSend(e)}>
                                <i class="fa-solid fa-paper-plane" style={{ cursor: "pointer" }}></i>
                            </div>
                        </div>

                    </div>
                }

                {
                    allComments.length > 0 &&
                    allComments
                    // .sort((a, b) => a._id - b._id)
                        .map((comment, index) => {
                            return (
                                <>
                                    <div className='ownpostothercommentdivmain'>
                                        <div className='profikellcmnjhhrrddivflxttst'>
                                            <div className='profikellcmntimgdivflxttst'>
                                                <div className='commentproofilleimg'>
                                                    <img src={comment?.commentedBy[0]?.profilePhoto || cmtprofiletwoimg} alt='...' />
                                                </div>
                                                <p className='commporosttxttnamepp'>{comment?.commentedBy[0]?.personName}</p>
                                            </div>
                                            <p className='commporosttxttnamepp'>{formatDateAgo(comment?.createdOn)}</p>
                                        </div>
                                        <p className='commsoprpodttctxppp'>
                                            {comment?.comment}
                                        </p>
                                    </div>
                                </>
                            )
                        })
                }
                {/* <div className='ownpostothercommentdivmain'>
                                                                <div className='profikellcmnjhhrrddivflxttst'>
                                                                    <div className='profikellcmntimgdivflxttst'>
                                                                        <div className='commentproofilleimg'>
                                                                            <img src={cmtprofiletwoimg} alt='...' />
                                                                        </div>
                                                                        <p className='commporosttxttnamepp'>John Doe</p>
                                                                    </div>
                                                                    <p className='commporosttxttnamepp'>1 hour ago</p>
                                                                </div>
                                                                <p className='commsoprpodttctxppp'>
                                                                    I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                                                </p>
                                                            </div> */}

            </div>
        </>
    )
}

export default PostComments
