import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../utils/HttpClient';

//get Logged User's Posts'
export const fetchMyBusinessPosts = createAsyncThunk('Data/MyBusinessPost', async () => {
    const response = await HttpClient.requestData("my-post", "GET", {});
    console.log("profff148", response)
    return response.data;
});

//get All Posts
export const fetchAllBusinessPosts = createAsyncThunk('Data/AllBusinessPost', async () => {
    const response = await HttpClient.requestData("all-business-post", "GET", {});
    return response.data;
});

//get All Posts
export const LoadBusinessPostsInBackground = createAsyncThunk('Data/AllBusinessPostsInBG', async () => {
    const response = await HttpClient.requestData("all-business-post", "GET", {});
    return response.data;
});

// Create the Data slice
const businessPostSlice = createSlice({
    name: 'Data',
    initialState: {
        MyBusinessPosts: [],
        AllBusinessPosts: [],
        status: '',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            // Fetching My Business Posts Data
            .addCase(fetchMyBusinessPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMyBusinessPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.MyBusinessPosts = action?.payload
                    .filter((item) => { return item.hideStatus === undefined || !item.hideStatus })
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            })
            .addCase(fetchMyBusinessPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.MyBusinessPosts = null;
                state.error = action?.error.message;
            })
            // Fetching All Business Posts Data
            .addCase(fetchAllBusinessPosts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllBusinessPosts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.AllBusinessPosts = action?.payload
                    .filter((item) => { return item.hideStatus === undefined || !item.hideStatus })
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            })
            .addCase(fetchAllBusinessPosts.rejected, (state, action) => {
                state.status = 'failed';
                state.AllBusinessPosts = null;
                state.error = action?.error.message;
            })
            // Fetching All Business Posts Data in Background
            .addCase(LoadBusinessPostsInBackground.pending, (state) => {
                // state.status = 'loading';
            })
            .addCase(LoadBusinessPostsInBackground.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.AllBusinessPosts = action?.payload
                    .filter((item) => { return item.hideStatus === undefined || !item.hideStatus })
                    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            })
            .addCase(LoadBusinessPostsInBackground.rejected, (state, action) => {
                state.status = 'failed';
                state.AllBusinessPosts = null;
                state.error = action?.error.message;
            })
    },
});

export const { } = businessPostSlice.actions
export default businessPostSlice.reducer

// Export the asynchronous thunks for use in components or other parts of your application