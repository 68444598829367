import React, { useState } from "react";
import uploadimg from "../Images/uploadimg.png";
import HttpClient from "../utils/HttpClient";

const Galleryeditmodal = ({ closemodal, data, updateForm, setData }) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState({
    image: "",
    title: "",
    shortDesc: "",
  });

  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUploaded({ ...uploaded, [name]: value });

    // Clear the title error as the user types
    if (name === "title") {
      setTitleError("");
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);

    try {
      const res = await HttpClient.fileUplode("image-upload", "POST", formData);
      if (res.status) {
        setUploaded({ ...uploaded, image: res.data.url });
        setUrl(res?.data?.url);
        setImageError(""); // Clear the error on successful upload
      } else {
        console.error("Error while uploading image");
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveChanges = () => {
    let hasError = false;

    if (!uploaded.title) {
      setTitleError("Title is required.");
      hasError = true;
    }

    if (!uploaded.image) {
      setImageError("Image is required.");
      hasError = true;
    }

    if (hasError) return;

    // Ensure `data.gallery` exists and is an array
    const updatedGallery = Array.isArray(data.gallery)
      ? [...data.gallery, uploaded]
      : [uploaded];

    updateForm("gallery", updatedGallery);
    closemodal(false);
  };

  return (
    <div className="uploabannermodalbgshadow">
      <div className="upgalerymodalbg">
        <div className="crossdivicon" onClick={() => closemodal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="upldbantrtupldbannerdiv">
          <h4 className="upldbannertrtxtpp">Upload gallery image</h4>
          <p className="selletxtcbabbtxttpp">
            Select and upload the files of your choice
          </p>
        </div>
        <div className="uploadaboxafgaeddpadikjnffdiv">
          <div className="uploadaboxdeshhbdiv">
            {url ? (
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <i
                  className="fa-solid fa-xmark delete-icon"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    fontSize: "20px",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                ></i>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px", // optional, for rounded corners
                  }}
                  src={url}
                  alt="uploaded image"
                />
              </div>
            ) : (
              <div className="filedibbhsjj">
                <input
                  type="file"
                  className="filedibbhsjjdiflediv"
                  onChange={handleFileChange}
                />
                <div className="uploadimgdibbavimg">
                  <img src={uploadimg} alt="Preview" />
                </div>
                <h4 className="choosttflttxtpp">
                  Choose a file upload it here
                </h4>
                <p style={{textAlign:"center"}}>(Pixel Size : 640 x 428 PNG/JPEG )</p>
                <p className="jjshpsttsrrtxtpp">
                  JPEG, PNG formats, up to 1 MB
                </p>
                <button style={{ margin: "auto" }} className="bttserfilelytxyp">
                  Browse File
                </button>{" "}
              </div>
            )}
          </div>
          {imageError && <p className="error">{imageError}</p>}

          <div className="galrrttxtittxttxtppdib">
            <p className="galrrttxtittxttxtpp">Title</p>
            <input
              value={uploaded.title}
              name="title"
              type="text"
              className="insttsbtrxrrds"
              onChange={handleChange}
              placeholder="Enter image title"
            />
            {titleError && <p className="error">{titleError}</p>}
          </div>

          <div className="galrrttxtittxttxtppdib">
            <p className="galrrttxtittxttxtpp">Short description</p>
            <textarea
              value={uploaded.shortDesc}
              name="shortDesc"
              rows={4}
              cols={4}
              className="insttsbtrxrrdstexrare"
              onChange={handleChange}
              placeholder="Enter a short description (100 words)"
            />
            <p className="ttsrrhhshwoolumiuitstxtt">100 Words</p>
          </div>

          <div className="svavtatvvbtnnmdivmaokin">
            <button onClick={saveChanges} className="svavtatvvbtnnm">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galleryeditmodal;