import React, { useEffect, useRef, useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import vectorarrow from "../../src/Images/vectorarrow.png"
import HttpClient from '../utils/HttpClient';
import toast, { useToaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
const BlmEnquiryModal = ({ closemodal, post }) => {
    const { SingleUserData } = useSelector((state) => state.app);
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }

    const initialData = {
        profileId: SingleUserData[0]?._id,
        name: "",
        workEmail: "",
        phoneNo: 0,
        typeOfEnquiry: "",
        enquirySubject: "",
        enquiry: "",
        file: ""
    }
    const [formData, setFormData] = useState(initialData);
    const updateForm = (name, value) => {
        // console.log("DATA23",name,value);

        setFormData((prev) => ({ ...prev, [name]: value }));
    }

    const [fileName, setFileName] = useState("");
    const [uploadedFilePath, setUploadedFilePath] = useState("")

    const imageUpload = async (file) => {
        if (!file) return; // Ensure there is a file to upload

        // setLoading(true);
        const formData = new FormData();
        formData.append("image", file);

        try {
            console.log("Uploading image:", file);
            // const res = await HttpClient.fileUplode("image-upload", "POST", formData);
            const res = await HttpClient.fileUploadWithoutAuth("image-upload", "POST", formData);
            if (res.status) {
                // setData(res.data.url);
                updateForm("file", res.data.url)
                // setUploadedFilePath(res.data.url)
                // setHasData(true);
            } else {
                console.log("Error while uploading image");
            }
        } catch (error) {
            console.error("Upload failed:", error);
        } finally {
            // updateForm("file", res.data.url)
            // setLoading(false);
        }
    };
    const [uploadedFilePreview, setUploadedFilePreview] = useState("No file chosen")
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
            // setUploadedFilePreview(event.target.files[0].name.split('.').pop().toLowerCase())
            setUploadedFilePreview(event.target.files[0].name)
            imageUpload(event.target.files[0]);
        } else {
            // setFileName("No file chosen");
            setUploadedFilePreview("No file chosen")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            ...formData
            // file: uploadedFilePath,
        }
        data.phoneNo = Number(data.phoneNo);
        console.log("formData79", data);
        // return

        try {
            const response = await HttpClient.requestData(
                "refer-enquiry",
                "POST",
                data
            );
            if (response.status) {
                // Navigate to payment page
                // Navigate("/searchhome");
                // Navigate("/");
                // dispatch(getOwnProfile())

                // console.log("RESP", response);
                // closemodal()
                toast.success("Message sent successfully!")

            } else {
                toast.error("Error occured! Please try again.");
                console.log("RESP", response);
            }
        } catch (error) {
            console.error("Error submitting Post:", error);
            console.log("RESP", error);
        } finally {
            // dispatch(fetchMyBusinessPosts())
            closemodal()
        }
    }

    const [ddlTypeOfEnquery, setDdlTypeOfEnquery] = useState(false)
    const ddlTypeOfEnqueryClick = () => {
        setDdlTypeOfEnquery(!ddlTypeOfEnquery)
    }

    const typeOfEnquery = [
        {
            name: "Buy/Sell",
            _id: "1"
        },
        {
            name: "General",
            _id: "2"
        }
    ]

    const divRef = useRef(null);
        const handleClickOutside = (e) => {
            // Check if the click is outside the referenced div
            if (divRef.current && !divRef.current.contains(e.target)) {
                // console.log('Clicked outside of the div');
                // Call your method here
                // alert(1)
                setDdlTypeOfEnquery(false)
            }
        };
        useEffect(() => {
            // Add event listener when the component mounts
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Cleanup the event listener when the component unmounts
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

    return (
        <div className='otherprofudvtmodalbackbox'>
            <div className='enquerysmokdnndbndbg'>
                <div className="threedorttsdcoroodivflx">
                    <div className="imnmnprhhhsbdijnvvgbsj" onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt="..." />
                    </div>
                </div>

                <div class="psendwendijndwjjwndiv">
                    <p class="breyuuayproosttxppp">Send Enquiry</p>
                    <div className='compamydinfodivmainbtmhuhauhdsicflx'>
                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Full Name <span> * </span></p>
                            <input type="text" placeholder="Enter your name" className="inpustxocntbusuitxtppnewallattyy"
                                value={formData.name || ""}
                                onChange={(e) => updateForm("name", e.target.value)}
                            />
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Mobile number  <span> * </span></p>
                            <input type="number" placeholder="Enter mobile number" className="inpustxocntbusuitxtppnewallattyy"
                                value={formData.phoneNo || ""}
                                onChange={(e) => updateForm("phoneNo", e.target.value)}
                            />
                        </div>

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Work email <span> * </span></p>
                            <input type="text" placeholder="Enter your active email address" className="inpustxocntbusuitxtppnewallattyy"
                                value={formData.workEmail || ""}
                                onChange={(e) => updateForm("workEmail", e.target.value)}
                            />
                        </div>

                        {/* <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Type of enquiry <span> * </span></p>
                            <div className='dropwdowndivfrmbusineenewwlas'>
                                <p className='vectoraarowdivomgulmaili'>
                                    Buy/Sell
                                </p>
                                <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                    <img src={vectorarrow} alt='...' />
                                </div>
                                {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                    <ul className='vectoraarowdivomgulmaiul'>
                                        <li className='vectoraarowdivomgulmaili'>
                                            Buy/Sell
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div> */}

                        <div className='compamydinfodivmdiobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Type of enquiry <span> * </span></p>
                            <div className='dropwdowndivfrmbusineenewwlas'>
                                {
                                    formData.typeOfEnquiry ? (
                                        <p className='vectoraarowdivomgulmaili'>
                                            {formData.typeOfEnquiry}
                                        </p>
                                    ) : (
                                        <p className='vectoraarowdivomgulmaili'>
                                            --- Choose ---
                                        </p>
                                    )
                                }

                                <div className='vectoraarowdivomgnenwlal' onClick={ddlTypeOfEnqueryClick}>
                                    <img src={vectorarrow} alt='...' />
                                </div>
                                {ddlTypeOfEnquery && <div className='vectoraarowdivomgulmai'
                                ref={divRef}
                                >
                                    <ul className='vectoraarowdivomgulmaiul'>
                                        {typeOfEnquery.map((type, index) => (
                                            <li
                                                key={index}
                                                className="vectoraarowdivomgulmaili"
                                                onClick={(e) => {
                                                    // handleInputChange("state", state);
                                                    // toggleDropdown("state");
                                                    updateForm("typeOfEnquiry", type?.name)
                                                    ddlTypeOfEnqueryClick()
                                                }}
                                            >
                                                {type?.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                            </div>
                        </div>

                        <div className='_compamydinfodivmdiobbdgppshg compamydinfodivtesxtrareobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Enquiry subject <span> * </span></p>
                            <input type="text" placeholder="Enter a subject" className="inpustxocntbusuitxtppnewallattyy"
                                value={formData.enquirySubject || ""}
                                onChange={(e) => updateForm("enquirySubject", e.target.value)}
                            />
                        </div>

                        <div className='compamydinfodivtesxtrareobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Enquiry message <span> * </span></p>
                            <textarea rows={4} cols={4} className='ennsjjuwtysttsxttaytredg'
                                value={formData.enquiry || ""}
                                onChange={(e) => updateForm("enquiry", e.target.value)}
                            />
                        </div>

                        {/* <div className='compamydinfodivtesxtrareobbdgppshg'>
                            <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                            <div className='chossefilkklkebdtrfdiv'>
                                <div className='enuqteyschosefllfbrdttdivdkdlnb'>


                                    <button className='enuqteyschosefllfbrdtt'>
                                        Choose file
                                    </button>
                                    <p>No file chosen</p>
                                </div>
                            </div>
                            <p className='alkoowjdiisdttxpp'>Allowed file types: JPG, PNG, PDF, Maximum allowed size is 2 MP</p>

                        </div> */}

                        <div className='poysttdiobnnnmmmnjm'>
                            <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                            <div className='chossefilkklkebdtrfdiv'>
                                <div className='enuqteyschosefllfbrdttdivdkdlnb'>
                                    <div class="custom-file-upload">
                                        <label for="file-upload" class="file-label">
                                            Choose file
                                        </label>
                                        <input id="file-upload" type="file" onChange={handleFileChange} />
                                        {/* <span id="file-chosen">{fileName}</span> */}
                                        <span id="file-chosen">{uploadedFilePreview}</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="svavtatvvbtnnmdivmaokin">
                        <button className="svavtatvvbtnnmsendferdgf"
                            onClick={(e) => handleSubmit(e)}
                        >Send enquiry</button>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default BlmEnquiryModal
