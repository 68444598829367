import React, { useContext, useEffect, useState } from 'react'
import EditProfileblm from './EditProfileblm';
import Blmpostadd from './Blmpostadd';
import Viewersengagement from './Viewersengagement';
import Upgradeprofile from './Upgradeprofile';
import Contactdetaiilsprofile from './Contactdetaiilsprofile';
import Allpostownpage from './Allpostownpage';
import { useDispatch, useSelector } from "react-redux";
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import BlmPageEnquiries from './BlmPageEnquiries';
import { fetchPageEnquiries } from '../../Redux/Slice/EnquirySlice';
import BlmPostEnquiries from './BlmPostEnquiries';
import { useLocation } from 'react-router-dom';

const OwnEnquiries = () => {
    const { SearchBar, ProfileSubmenuDropDownToggle, UserProfileID, SingleUserData } = useSelector((state) => state.app);

    const location = useLocation();
    const colordata = useContext(Colorcontext);
    const [tabpostall, setTabpostall] = useState(location?.state?.enquiryTab || 1)
    const dispatch = useDispatch()
    useEffect(() => {
        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);

    console.log("DD29N",location?.state?.enquiryTab);
    

    useEffect(()=>{
        setTabpostall(location?.state?.enquiryTab || 1)
    },[location?.state?.enquiryTab])

    useSelector((state) => state.app);

    const postdicclickbtn = (tabnumber) => {
        setTabpostall(tabnumber)
    }

    const [filterType, setFilterType] = useState("")
    const handleFilterType = (e) => {
        e.preventDefault()
        dispatch(fetchPageEnquiries())
        setFilterType(e.target.name)
    }

    const [pageCounter, setPageCounter] = useState(0)
    const [postCounter, setPostCounter] = useState(0)

    return (
        <section className='ownblmprofiledivmaoin'>
            <div className='cust_container'>
                <div className='ownblmprofiledivflx'>
                    <div className='ownblmprofiledivleft'>
                        <div className='repsonsivedivcls'>
                            {/* For mobile responsive code start  */}
                            <div className=' oenblmsnfidivmainleft'>
                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                    {/* <EditProfileblm /> */}
                                    <EditProfileblm data={UserProfileID} />
                                </div>

                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                    <Upgradeprofile />
                                </div>



                            </div>


                            <div className='oenblmsnfidivmainright'>
                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                    <Contactdetaiilsprofile data={UserProfileID} />
                                </div>
                                <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                    <Blmpostadd />
                                </div>


                            </div>
                            {/* For mobile responsive code  end */}
                        </div>


                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            {/* <EditProfileblm /> */}
                            <EditProfileblm data={UserProfileID} />
                        </div>

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                                <div className='bmlmmaposttaddddib'>
                                    <div className='bmlmmaposttaddddibdivmain'>
                                        <p class="cinnattddttdtsttspo">Advanced find</p>
                                        <div className='poisyybtnmainlibkldivflx my-3'>
                                            <button type='button'
                                                className={filterType === "today" ? "ipyysffraedtn" : "direxrrcttstbtn"}
                                                name="today"
                                                onClick={(e) => handleFilterType(e)}
                                            >
                                                Today
                                            </button>
                                        </div>
                                        <div className='poisyybtnmainlibkldivflx my-3'>
                                            <button type='button'
                                                className={filterType === "week" ? "ipyysffraedtn" : "direxrrcttstbtn"}
                                                name="week"
                                                onClick={(e) => handleFilterType(e)}
                                            >
                                                This week
                                            </button>
                                        </div>
                                        <div className='poisyybtnmainlibkldivflx my-3'>
                                            <button type='button'
                                                className={filterType === "month" ? "ipyysffraedtn" : "direxrrcttstbtn"}
                                                name="month"
                                                onClick={(e) => handleFilterType(e)}
                                            >
                                                This month
                                            </button>
                                        </div>
                                        <div className='poisyybtnmainlibkldivflx my-3'>
                                            <button type='button'
                                                className={filterType === "all" ? "ipyysffraedtn" : "direxrrcttstbtn"}
                                                name="all"
                                                onClick={(e) => handleFilterType(e)}
                                            >
                                                Show all
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Blmpostadd />
                        </div>

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Upgradeprofile />
                        </div> */}

                        <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                            <Contactdetaiilsprofile
                                data={UserProfileID}
                            />
                        </div>



                    </div>
                    <div className='ownblmprofiledivright'>
                        <div className='allpostmyapooskducvsdic'>
                            <div className='allpostmyapooskducvsdictop'>
                                <div className='postamtydposdivflx'>
                                    <button className={tabpostall === 1 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(1)}>Page Enquiries  ({pageCounter})</button>
                                    <button className={tabpostall === 2 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(2)}>Post Enquiries  ({postCounter})</button>
                                </div>
                            </div>

                            <div className='allpostmyapooskducvsdicbottom'>
                                {tabpostall === 1 && (
                                    <>
                                        <BlmPageEnquiries filter={filterType} setCounter={setPageCounter} />
                                    </>
                                )}
                                {tabpostall === 2 && (
                                    <>
                                        <BlmPostEnquiries filter={filterType} setCounter={setPostCounter} />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OwnEnquiries
