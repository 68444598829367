import React, { useRef, useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import threedowttsimhgicon from "../../src/Images/threedowttsimhgicon.png";
import postbanneimmhg from "../../src/Images/postbanneimmhg.png";
import prodlikeicon from "../../src/Images/prodlikeicon.png";
import commenytstproodiuificon from "../../src/Images/commenytstproodiuificon.png";
import cmtprofileoneimg from "../../src/Images/cmtprofileoneimg.png";
import cmtprofiletwoimg from "../../src/Images/cmtprofiletwoimg.png";
import msgiocn from "../../src/Images/msgiocn.png";
import Neoologoasjji from "../../src/Images/Neoologoasjji.png";
import { formatDateAgo } from '../utils/Helper';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { commentAdd, ShareModalonOff } from '../Redux/Slice/PostSlice';
import { useDispatch, useSelector } from 'react-redux';
import ProfileShare from './ProfileShare';
import { LoadBusinessPostsInBackground } from '../Redux/Slice/BusinessPostSlice';
const Ownpostpagemodal = ({ closemodal, data }) => {
    const divRef = useRef(null);
    const [text, setText] = useState("");
    const [commentboxmodal, setCommentboxmodal] = useState(false);
    const [loadmoremodala, setLoadmoremodala] = useState(false);
    const [threedpotsspclmodal, setThreedpotsspclmodal] = useState(false);
    const commentsboxoscopemnn = () => {
        setCommentboxmodal(!commentboxmodal);
    }
    const lodamoisrrdedivbmodal = () => {
        setLoadmoremodala(!loadmoremodala)
    }
    const threedotssettingclcil = () => {
        setThreedpotsspclmodal(!threedpotsspclmodal)
    }
    console.log("DATAP26", data);

    const location = useLocation();
    const copyPostLink = (e, postId) => {
        e.preventDefault()
        console.log("location166", location);
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        navigator.clipboard
            .writeText(postUrl)
            .then(() => {
                // alert("Copied")
                toast.success("Copied Successfully");
                setThreedpotsspclmodal(false)
                // setCopied(true);
                // setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            })
            .catch((err) => console.error('Failed to copy text: ', err));
    }
    const { ShareModalOpenClose, UserProfileID, SingleUserData } = useSelector((state) => state.app);
    const dispatch = useDispatch()
    const [sharemodal, setSharemodal] = useState(false);
    const [postLink, setPostLink] = useState("")
    const shareClick = (e, postId) => {
        e.preventDefault()
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        setPostLink(postUrl)
        setSharemodal(!sharemodal);
        dispatch(ShareModalonOff(true));
    };

    const [commentText, setCommentText] = useState("")
    const handleCommentSend = (e, postId) => {
        e.preventDefault()
        const data = {
            postId: postId,
            comment: commentText
        }

        console.log("DATA70", data);
        // return
        dispatch(commentAdd(data))
        dispatch(LoadBusinessPostsInBackground())

        setCommentText("")
        // setAllComments((prevComments) => [
        //     { comment: commentText, postId: postId, commentedBy: [{ profilePhoto: UserProfileID?.profilePhoto, personName: UserProfileID?.personName }] },
        //     ...prevComments
        // ]);
    }

    return (
        <>
            <div className='otherprofudvtmodalbackbox'>
                <div className='otherprofileproductbgmain'>

                    {/* <div className='threedorttsdcoroodivflx'>
                    <div className='threediostyiicpn'>
                    <i className="fa-solid fa-ellipsis"></i>
                    </div>
                    <div className='imnmnproduuccrtisocross' onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt='...' />
                    </div>
                </div> */}

                    <div className='produtitleimggsdescrdivnewallada'>

                        <div className='allpostowndmaimndivallelemntnesmain'>
                            <div className='allpostowndmaimndivallelemnt'>
                                <div className='allpostimagedesuigfnatinob'>
                                    <div className='allposttslodjjimg'>
                                        <img src={data?.userData?.businessProfilePic || Neoologoasjji} alt='...' />
                                    </div>
                                    <div className=''>
                                        <h4 className='neeporoalposttsheadr'>{data?.userData?.companyName}</h4>
                                        <p className='cmskshhstdiifgysyttxtpop'>{data?.userData?.businessProductCat?.join(" | ")}</p>
                                    </div>
                                </div>
                                <div className='crosasddoproidikdsjbhidivflx'>
                                    <div className='threediostyiicpnggsuc'>
                                        <div className='threediostyiicpn' onClick={threedotssettingclcil}>
                                            <i className="fa-solid fa-ellipsis"></i>
                                        </div>
                                        {threedpotsspclmodal && <div className='threedotssettingdiv'>
                                            <ul className="userMenu">
                                                <li>
                                                    <a
                                                        href="javascript:"
                                                        onClick={(e) => copyPostLink(e, data?._id)}
                                                    >
                                                        <i className="fa-solid fa-link mr-2"></i>
                                                        Copy post link
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="javascript:"
                                                        onClick={(e) => shareClick(e, data?._id)}
                                                    >
                                                        <i className="fa-solid fa-share-nodes  mr-2"></i>
                                                        Share post link
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/">

                                                        <i className="fa-regular fa-bookmark mr-2"></i>
                                                        Save post
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="/">

                                                        <i className="fa-solid fa-eye-slash mr-2"></i>
                                                        Not interested
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>}
                                    </div>


                                    <div className='crossidovpostimgdiv' onClick={() => closemodal()}>
                                        <img src={crosssimgiocn} alt='...' />
                                    </div>
                                </div>
                            </div>

                            <div className='beaiuttisprofuductimh'>
                                <img src={data?.file || postbanneimmhg} alt='...' />
                            </div>

                        </div>

                        <div className=''>
                            <p className='producttdescttxpppyip'>{data?.description || "..."}</p>
                        </div>

                        <div className=''>
                            <p className='timttksopooiisuttxpp'>
                                {formatDateAgo(data?.createdOn)}
                            </p>
                        </div>
                    </div>

                    <div className={commentboxmodal ? 'heijggsctrdjpprodfuiiciv actv' : "heijggsctrdjpprodfuiiciv"}>
                        <div className='producudtlikecomrnttsennsuiquerydiv'>
                            <div className='prodctlikecmntenquerydiv'>
                                <div className='produucvtsdlikeinmncomnfdivvhjjd'>
                                    <div className='produucvtsdlikeinmncomnf'>
                                        <img src={prodlikeicon} alt='' />

                                    </div>
                                    <p className='likkskjsbtcomnbvtyoipuxtt'>Like <span>{data?.PostLikeCount}</span></p>
                                </div>

                                <div className='produucvtsdlikeinmncomnfdivvhjjd' onClick={commentsboxoscopemnn}>
                                    <div className='produucvtsdlikeinmncomnf'>
                                        <img src={commenytstproodiuificon} alt='' />

                                    </div>
                                    <p className='likkskjsbtcomnbvtyoipuxtt'>Comment </p>
                                </div>


                                <div className='produucvtsdlikeinmncomnfdivvhjjd'>
                                    <div className='produucvtsdlikeinmncomnf'>
                                        <img src={msgiocn} alt='' />

                                    </div>
                                    <p className='likkskjsbtcomnbvtyoipuxtt'>Send Equiry </p>
                                </div>
                            </div>
                        </div>

                        {commentboxmodal && 
                        
                        <div className='producytppostcommnentdiv'
                        style={{display:"none"}}
                        >
                            <div className='commuisonnporttsidivrrls'>
                                {/* <div
                                    ref={divRef}
                                    contentEditable
                                    className='textardibbrdrdivmain'
                                >
                                    {text}
                                </div>
                                <div className='posyynbmrmodiuvvmain'>
                                    <button className='posyynbmrmodiuvsujusvbntn'>Post</button>
                                </div> */}


                                <div className='textareadibmainmainbrdrnewalada'>
                                    <div className='textareadibmain'>
                                        <textarea rows={4} cols={4} className='textareadibmainrerarenewalada' placeholder='Write a Comment'
                                            onChange={(e) => setCommentText(e.target.value)}
                                            value={commentText || ""}
                                        />
                                    </div>
                                    <div className="mesagsendidvmain"
                                        onClick={(e) => handleCommentSend(e, data?._id)}
                                    >
                                        <button className='posyynbmrmodiuvsujusvbntn'>Post</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                        }

                        <div className='commenytstthowitxtpehht'>
                            {
                                data?.PostCommentData.map((comment, index) => {
                                    return (
                                        <>
                                            <div className='commentshpwfrsttcmontdiv'>
                                                <div className='profikellcmnjhhrrddivflxttst'>
                                                    <div className='profikellcmntimgdivflxttst'>
                                                        <div className='commentproofilleimg'>
                                                            <img src={comment?.commentedBy[0]?.profilePhoto || cmtprofileoneimg} alt='...' />
                                                        </div>
                                                        <p className='commporosttxttnamepp'>{comment?.commentedBy[0]?.personName}</p>
                                                    </div>
                                                    <p className='commporosttxttnamepp'>{formatDateAgo(comment?.createdOn)}</p>
                                                </div>
                                                <p className='commsoprpodttctxppp'>
                                                    {comment?.comment}
                                                </p>
                                            </div>
                                        </>
                                    )
                                })
                            }


                            {/* <div className='commentshpwfrsttcmontdiv'>
                                <div className='profikellcmnjhhrrddivflxttst'>
                                    <div className='profikellcmntimgdivflxttst'>
                                        <div className='commentproofilleimg'>
                                            <img src={cmtprofiletwoimg} alt='...' />
                                        </div>
                                        <p className='commporosttxttnamepp'>John Doe</p>
                                    </div>
                                    <p className='commporosttxttnamepp'>1 hour ago</p>
                                </div>
                                <p className='commsoprpodttctxppp'>
                                    I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                </p>
                            </div> */}

                            {loadmoremodala && <div className=''>
                                <div className='commentshpwfrsttcmontdiv'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofileoneimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                                <div className='commentshpwfrsttcmontdiv'>
                                    <div className='profikellcmnjhhrrddivflxttst'>
                                        <div className='profikellcmntimgdivflxttst'>
                                            <div className='commentproofilleimg'>
                                                <img src={cmtprofiletwoimg} alt='...' />
                                            </div>
                                            <p className='commporosttxttnamepp'>John Doe</p>
                                        </div>
                                        <p className='commporosttxttnamepp'>1 hour ago</p>
                                    </div>
                                    <p className='commsoprpodttctxppp'>
                                        I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                    </p>
                                </div>
                            </div>}

                            {/* <button className='lodmrebtnmainndiv' onClick={lodamoisrrdedivbmodal}>Load More</button> */}



                        </div>

                    </div>


                </div>

            </div>
            {ShareModalOpenClose && (
                <ProfileShare
                    whatsApp={postLink}
                    linkedInLink={postLink}
                    facebookLink={postLink}
                    userLink={postLink}
                    instaLink={postLink}
                    emailId={postLink}
                // closeModal={setSharemodal}
                />
            )}
        </>

    )
}

export default Ownpostpagemodal
