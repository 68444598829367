import React, { useState } from "react";
import uploadimg from "../../src/Images/uploadimg.png";
import HttpClient from "../../src/utils/HttpClient";

const Editbannermodalbml = ({ closemodal, url, setUrl, updateForm }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [hasData, setHasData] = useState(false);

  console.log("URL10X", url);



  const imageUpload = async (file) => {
    if (!file) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);

    try {
      console.log("Uploading image:", file);
      const res = await HttpClient.fileUploadWithoutAuth("image-upload", "POST", formData);
      if (res.status) {
        console.log("ser25", res.data.url);
        setUrl(res.data.url);
        setHasData(true);
      } else {
        console.error("Error while uploading image");
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    // alert(3)
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      imageUpload(file);
    }
  };

  const handleSave = () => {
    if (url) {
      updateForm("businesBanner", url);
      setUrl("");
      setFile(null);
      setHasData(false);
      closemodal();
    }
  };

  return (
    <div className="uploabannermodalbgshadow">
      <div className="uploabannermodalbg">
        <div className="crossdivicon" onClick={() => closemodal()}>
          <i className="fa-solid fa-xmark"></i>
        </div>

        <div className="upldbantrtupldbannerdiv">
          <h4 className="upldbannertrtxtpp">Upload Banner Image</h4>
          <p className="selletxtcbabbtxttpp">
            Select and upload the files of your choice
          </p>
        </div>

        <div className="uploadaboxdeshhbdivbrdrmain"
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize:"cover"
          }}
        >
          {/* <div className="uploadaboxdeshhbdiv">
            {url ? (
              <img
                src={url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                alt="Uploaded banner"
                className="uploadedImagePreview"
              />
            ) : (
              <div className="filedibbhsjj">
                <input
                  type="file"
                  className="filedibbhsjjdiflediv"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <div className="uploadimgdibbavimg">
                  <img src={uploadimg} alt="Placeholder for upload" />
                </div>
                <h4 className="choosttflttxtpp">
                  Choose a file to upload it here
                </h4>
                <p className="jjshpsttsrrtxtpp">
                  JPEG, PNG formats, up to 1 MB
                </p>
                <button
                  className="bttserfilelytxyp"
                  style={{
                    margin: "auto",
                  }}
                  onClick={() =>
                    document.querySelector(".filedibbhsjjdiflediv").click()
                  }
                >
                  Browse File
                </button>
              </div>
            )}
          </div> */}

          <div className="uploadaboxdeshhbdiv">

            <div className="filedibbhsjj">
              <input
                type="file"
                className="filedibbhsjjdiflediv"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div className="uploadimgdibbavimg">
                <img src={uploadimg} alt="Placeholder for upload" />
              </div>
              <h4 className="choosttflttxtpp">
                Choose a file to upload it here
              </h4>
              <p className="jjshpsttsrrtxtpp">
                JPEG, PNG formats, up to 1 MB
              </p>
              <button
                className="bttserfilelytxyp"
                style={{
                  margin: "auto",
                  background:"#fff"
                }}
                onClick={() =>
                  document.querySelector(".filedibbhsjjdiflediv").click()
                }
              >
                Browse File
              </button>
            </div>
          </div>



          {hasData && (
            <div className="svavtatvvbtnnmdivmaokin">
              <button className="svavtatvvbtnnm" onClick={handleSave}>
                Save
              </button>
            </div>
          )}
        </div>

        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editbannermodalbml;
