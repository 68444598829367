import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import mobile_icon from "../../Images/mobile_icon.png";
import phone_icon from "../../Images/phone_icon.png";
import whatsapp_icon from "../../Images/whatsapp_icon.png";
import mail_icon from "../../Images/mail_icon.png";
import website_icon from "../../Images/website_icon.png";
import location from "../../Images/location.png";
import arrowdirections from "../../Images/arrowdirections.png";
import Companyinfoeditmodal from "../../Modal/Companyinfoeditmodal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const ContactDetailItem = ({ icon, text, address = "", emirate = "", country = "" }) => {
  // console.log("text123:", text, typeof (address), emirate, country);
  return (
    <div className="infodetatddivfllsxdlx">
      <div className="indfodetatildiv">
        <img src={icon} alt="icon" />
      </div>
      <p className="ingfddtertrrxrpp">{text || "N/A"}{address && <><br />{address}</>}{emirate && country && <><br />{emirate + ", " + country}</>}
      </p>
    </div>

  );
};

const Contactdetaiilsprofile = ({ data = {}, setData, updateForm }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [contactEditModal, setContactEditModal] = useState(false);
  const handleContactModalClick = () => setContactEditModal(!contactEditModal);
  console.log("LINE29", updateForm);

  const handleOpenInNewTab = (e) => {
    e.preventDefault(); // Prevent the default navigation
    window.open(data?.businessLocationLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="contactdetsdiv">
        <div className="contactdetsdivdivbmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Contact Details</p>
            {
              UserProfileID?._id === data?._id && updateForm !== undefined &&
              <div className="editeiconimg" onClick={handleContactModalClick}>
                <img src={editicon} alt="edit icon" />
              </div>
            }

          </div>
          <div className="indfodetatildivmainui">
            <ContactDetailItem
              icon={phone_icon}
              text={data?.telephoneNo || "N/A"}
            />
            <ContactDetailItem
              icon={mobile_icon}
              text={data?.mobileNo || "N/A"}
            />
            <ContactDetailItem
              icon={whatsapp_icon}
              // text={data?.mobileNo || "N/A"}
              text={data?.whatsappNo || "N/A"}
            />
            <ContactDetailItem icon={mail_icon} text={data?.emailId || "N/A"} />
            <ContactDetailItem
              icon={website_icon}
              // text={data?.webAddress || "N/A"}
              text={data?.businessWebsiteLink || data?.webAddress || "N/A"}
            />
          </div>
        </div>

        <div className="contactdetsdivdivbmaindivbtm">
          <ContactDetailItem icon={location} text={data?.address} address={data?.location} emirate={data?.emirate} country={data?.countryName} />
          <div className="direxrrcttstbtnmain">
            <button className="direxrrcttstbtn" onClick={handleOpenInNewTab}>
              Get Directions
              <img src={arrowdirections} alt="arrow icon" />
            </button>
            {/* <Link
            to="/"
            className="direxrrcttstbtn"
            onClick={handleOpenInNewTab}
            >
              Get Directions
              <img src={arrowdirections} alt="arrow icon" />
            </Link> */}
          </div>
        </div>
      </div>

      {contactEditModal && (
        <Companyinfoeditmodal
          data={data}
          closemodal={handleContactModalClick}
          updateForm={updateForm}
          setData={setData}
        />
      )}
    </>
  );
};

export default Contactdetaiilsprofile;
