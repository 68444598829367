import React, { useContext, useEffect, useState } from "react";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import searchicon from "../../Images/searchicon.png";
import searchresilutfilter from "../../Images/searchresilutfilter.png";
import logoownblm from "../../Images/logoownblm.png";
import logootherblm from "../../Images/logootherblm.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import Neoologoasjji from "../../Images/Neoologoasjji.png";
import lofogoeyyy from "../../Images/lofogoeyyy.png";
import paiisiidjuudug from "../../Images/paiisiidjuudug.png";
import askokdjjlobogo from "../../Images/askokdjjlobogo.png";
import imahggsercggdpremiy from "../../Images/imahggsercggdpremiy.png";
import prijjdhjjjjkj from "../../Images/prijjdhjjjjkj.png";
import { Link, useParams } from "react-router-dom";
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png";
import Ownpagesearchkeywordmodal from "../../Modal/Ownpagesearchkeywordmodal";
import { useNavigate, useLocation } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import close from "../../Images/close.png";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUserProfile, SetDisplayUserData } from "../../Redux/Slice/PostSlice";
import searchhomeprofileimg from "../../../src/Images/searchhomeprofileimg.png";
import cameralogo from "../../../src/Images/cameralogo.png";
import moment from "moment/moment";

const Blmemberlist = () => {
  const { UserProfileID, SingleUserData } = useSelector((state) => state.app);
  const colordata = useContext(Colorcontext);
  const dispatch = useDispatch();
  const [ownpagesearchkeay, setownpagesearchkeay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState([]);
  const [country, setCountry] = useState([]);
  const [designation, setDesignation] = useState([]);
  const { data, searchText } = location.state;
  const [searchedData, setSearchedData] = useState(data ?? []);
  const [text, setText] = useState(searchText ?? "");
  const [singleData, setSingleData] = useState();

  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };


  }, []);

  const getCountryName = (_id) => {
    const countryName = country.find((countr) => countr._id === _id);
    return countryName ? countryName.name : "United Arab Emirates";
  };

  const getDesignationName = (_id) => {
    const designationName = designation.find(
      (designation) => designation._id === _id
    );
    console.log("designationName :", designationName);
    return designationName ? designationName.designation : "";
  };

  const getCompanyName = (_id) => {
    const companyName = company.find((company) => company._id === _id);
    return companyName ? companyName.type : "";
  };

  const getCompany = async () => {
    const response = await HttpClient.requestData("companyType", "GET", "");
    if (response.status) {
      setCompany(response.data);
    } else {
      console.log("Error while fetching company data");
    }
  };

  const getCountry = async () => {
    const response = await HttpClient.requestData("countries-list", "GET", "");
    if (response.status) {
      console.log("name111 : ", response.data);

      setCountry(response.data);
    } else {
      console.log("Error while fetching country data");
    }
  };

  const getDesignation = async () => {
    const response = await HttpClient.requestData("designation", "GET", "");
    if (response.status) {
      setDesignation(response.data);
    } else {
      console.log("Error while fetching designation data");
    }
  };

  const [sponsorAdImage, setSponsorAdImage] = useState({})

  const getSponsorAd = async () => {
    const response = await HttpClient.requestData("all-sponserAd", "GET", "");
    if (response.status) {
      setSponsorAdImage(response.data[0] || {});
    } else {
      console.log("Error while fetching company data");
    }
  };

  console.log("SearchedData : ", searchedData);
  console.log("sponsorAdImage108", sponsorAdImage);


  const searchName = async () => {
    if (text) {
      let data = {
        searchName: text,
      };
      const response = await HttpClient.requestData(
        "search-business",
        "POST",
        data
      );
      if (response?.status) {
        console.log(response?.data);
        setSearchedData(response?.data);
      } else {
        console.log("Error while fetching the data ");
      }
    }
  };
  const ownpagesearchkeyclcik = () => {
    setownpagesearchkeay(!ownpagesearchkeay);
  };
  const handleownsecrh = () => {
    navigate("/ownsearchresult");
  };

  const handleViewProfile = (UserID) => {
    dispatch(getSingleUserProfile(UserID));
    // navigate(`/member/${singleData?._id}`)
    navigate(`/profile/${singleData?.userName?.trim()}`)
  };

  const handleSingleData = (item) => {
    setSingleData(item)
    // console.log("Sin230", item);

    dispatch(SetDisplayUserData([item]))
  }

  const handleMobilePageView = (e, item) => {
    e.preventDefault()
    setSingleData(item)
    dispatch(SetDisplayUserData([item]))
    // to={`/businesspage/${singleData?.companyName?.toLowerCase().replace(/\s+/g, "_")}`}
    navigate(`/businesspage/${singleData?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)
  }

  const handleMobileProfileView = (e, item) => {
    e.preventDefault()
    setSingleData(item)
    dispatch(SetDisplayUserData([item]))
    // to={`/businesspage/${singleData?.companyName?.toLowerCase().replace(/\s+/g, "_")}`}
    navigate(`/MemberOtherSavedProfile/${singleData?.userName?.trim()}`)
  }

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getCompany()
    getCountry()
    getDesignation()
    getSponsorAd()
  }, []);

  const handleKeyDown = (event) => {
    setText(event.target.value);
    if (event.key === "Enter") {
      searchName()
    }
  };

  return (
    <>
      <section className="ownseacrhresultpagemain">
        <div className="cust_container">
          <div className="searchhresultandfilterdivflx">
            <div className="searfchresultleftdiv">
              <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(event)=>handleKeyDown(event)}
                className="searfchresultleftdivmain"
                placeholder="Enter your text"
                style={{paddingRight:"70px"}}
              />
              {text && (
                <div
                  className="searchiconimgnew"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "7%",
                    cursor: "pointer",
                    top:"10px",
                      right:"45px",
                  }}
                  onClick={() => setText("")}
                >
                  <img
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                    src={close}
                    alt="closing button"
                  />
                </div>
              )}
              <div
                style={{
                  top: "10px",
                }}
                className="sectgresulticon"
                onClick={searchName}
              >
                <img src={searchicon} alt="..." />
              </div>
            </div>

            <div className="searchfilterdiv">
              <div
                className="searchfilterdivmain"
                onClick={ownpagesearchkeyclcik}
              >
                <div className="sercghghfilyerimg">
                  <img src={searchresilutfilter} alt="..." />
                </div>
              </div>
            </div>
          </div>

          <div className="ownseacrgresultpagedivbg">
            <div className="ownseacrgresultpagedivflx">

              {
                isMobile &&
                <div style={{ marginBottom: "1em" }}>
                  <p className="prPremiumofiletxtp">Sponsor Ad</p>
                  {
                    sponsorAdImage && (moment(sponsorAdImage?.expiryDate, "YYYY-MM-DD").isAfter(moment().startOf("day")) || moment(sponsorAdImage?.expiryDate, "YYYY-MM-DD").isSame(moment().startOf("day"))) ?
                      <div>
                        <a href={sponsorAdImage?.adLinkUrl} target="_blank">
                          <img src={sponsorAdImage?.adImage}
                            style={{
                              maxWidth: "100%"
                            }}
                          />
                        </a>
                      </div> :
                      <div className="boxosimaggedisahdowdiv">
                        <p className="tsttsggsiijxbhnsimag">300x250</p>
                      </div>
                  }
                </div>
              }










              <div className="ownseacrgresultpageleft">
                <p className="avoiaresrrstrtxppp">
                  About {searchedData.length} results
                </p>
                {searchedData.length === 0 ? (
                  <div className="serchresutllogodetaildivflx">
                    <p className="avoiaresrrstrtxppp">No results found</p>
                  </div>
                ) : (
                  searchedData.map((item, index) => {
                    return (
                      <div
                        className="serchresutllogodetaildivflx"
                        key={index}
                        // onClick={() => setSingleData(item)}
                        onClick={() => handleSingleData(item)}
                      >
                        <div className="serachresultlogoidiv">
                          <img src={item?.businessProfilePic || cameralogo} alt="..." />
                        </div>
                        <div className="serachresultdetailsdiv">
                          <h4 className="serctedheadrparatxt">
                            {item?.companyName} -{" "}
                            {getCompanyName(item?.companyTypeID)}
                          </h4>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {item?.businessProductCat?.map((it, index) => (
                              <p
                                className="diisjadfebnclkmdgragjksopjkl"
                                key={index}
                                style={{
                                  marginLeft: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                {it}{item?.businessProductCat.length > index + 1 && (" | ")}
                              </p>
                            ))}
                          </div>

                          <p className="serchrelusubtparatxtmm">
                            {item?.aboutBusiness?.length > 165 ? item?.aboutBusiness?.substring(0, 165) + '...' : item?.aboutBusiness}
                          </p>
                          {
                            isMobile &&
                            <div className="search-result-view" style={{display:"flex"}}>
                              <button onClick={(e) => handleMobilePageView(e, item)} style={{ margin: "0.5em 1em 0.5em 0" }}>View page</button>
                              {item?.memberStatus && <button onClick={(e) => handleMobileProfileView(e, item)} style={{ margin: "0.5em 0" }}>View profile</button>}
                            </div>
                          }
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              {singleData ? (
                <>
                  {
                    singleData.status === "Active" ? (
                      <>
                        <div className="ownseacrgresultpageright">
                          <p className="prPremiumofiletxtp">{singleData?._id === UserProfileID?._id ? "Your page" : singleData.memberStatus ? "Premium profile" : ""}</p>
                          <div className="ownseacrgresultpagerightbrdr">
                            <div
                              className="ownseacrgresultpagerightbrdrtop"
                            // onClick={handleotherserchresult}
                            >
                              <div className="ownseacrgresultpagerreslppimg">
                                <img src={singleData?.businesBanner || "https://st.depositphotos.com/2934765/53192/v/450/depositphotos_531920820-stock-illustration-photo-available-vector-icon-default.jpg"} alt="..." />
                              </div>

                              <div className="logostrdetyskksldovkkdpo">
                                <div className="logostretdijjkkkljujuo">
                                  {/* <img src={singleData?.businessProfilePic} alt="..." /> */}
                                  <img src={singleData?.businessProfilePic || cameralogo} alt="..."
                                    style={{
                                      border: "solid 3px #fff",
                                    }}
                                  />
                                </div>
                                <div className="allttehdiioskjetyapasedoib">
                                  {
                                    singleData?.businessStatus ? (
                                      <>
                                        <Link
                                          // to={`/member/${singleData?._id}`}
                                          to={`/businesspage/${singleData?.companyName?.toLowerCase().replace(/\s+/g, "_")}`}
                                          className="neosertsttfzrrtty"
                                        >
                                          {singleData?.companyName}
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <p className="neosertsttfzrrtty">
                                          {singleData?.companyName}
                                        </p>
                                      </>
                                    )
                                  }


                                  <p className="neosertsttfzrrttyadfd">
                                    {getCompanyName(singleData?.companyTypeID)}
                                  </p>
                                  <div className="dofleoodiistartdivflx">
                                    <p className="neosertsttfzrrttyadfdfff">
                                      {/* {singleData?.} */}
                                    </p>

                                    <div className="statrrdibvggshhghuju">
                                      <i class="fa-regular fa-star"></i>
                                      <i class="fa-regular fa-star"></i>
                                      <i class="fa-regular fa-star"></i>
                                      <i class="fa-regular fa-star"></i>
                                      <i class="fa-regular fa-star"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="ownseacrgresultpagerightbrdrtopbottom">
                              <div className="">
                                <p className="tttxtyaplattsyggjjkllkkk">
                                  {singleData?.location}
                                </p>
                                <p className="tttxtyaplattsyggjjkllkkk">
                                  {singleData?.emirate},{" "}
                                  {getCountryName(singleData?.country)}
                                </p>

                                <p className="phokennsjjsnyunnrbtk">
                                  Phone: {singleData?.mobileNo}
                                </p>

                                <Link className="serttxtghsytxoppjkjo" to="/">
                                  {singleData?.webAddress}
                                </Link>

                                <p className="opperpoodshheyxgbpppn">{ }</p>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {singleData?.businessProductCat?.map((it, index) => (
                                    <p
                                      className="diisjadfebnclkmdgragjksopjkl"
                                      key={index}
                                      style={{
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {it}{singleData?.businessProductCat.length > index + 1 && (" | ")}
                                    </p>
                                  ))}
                                </div>

                                {
                                  singleData?.memberStatus &&
                                  <>
                                    <div className="viewprofileimgaedivvhjjnflx">
                                      <div className="prijjdhjjjjkjaaimg">
                                        <img src={singleData?.profilePhoto || searchhomeprofileimg} alt="..." />
                                      </div>
                                      {/* <div
                                onClick={(e) => handleViewProfile(e, singleData?._id)}
                                className="bvhhjjapsidhbfbbrkjk"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                View profile
                              </div> */}
                                      <Link
                                        // onClick={handleViewProfile(singleData?._id)}
                                        // to={`/profile/${singleData?._id}`}
                                        to={`/MemberOtherSavedProfile/${singleData?.userName?.trim()}`}
                                        // to={`/profile/${singleData?.userName?.trim()}`}
                                        className="bvhhjjapsidhbfbbrkjk"
                                      >
                                        View profile
                                      </Link>
                                    </div>
                                  </>
                                }


                                <div className="">
                                  <p className="sclloidjjdttxppphjo">Social links</p>
                                  <div className="socijjjsjkjijnndnkdobvbbhflx">
                                    <Link
                                      to={singleData?.facebookLink}
                                      className="socijjjsjkjijnndnk"
                                    >
                                      <img src={facebook_icon} alt="..." />
                                    </Link>
                                    <Link
                                      to={singleData?.instaLink}
                                      className="socijjjsjkjijnndnk"
                                    >
                                      <img src={insta_icon} alt="..." />
                                    </Link>
                                    <Link
                                      to={singleData?.linkedInLink}
                                      className="socijjjsjkjijnndnk"
                                    >
                                      <img src={linkedin_icon} alt="..." />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="ownseacrgresultpageright">
                          <p className="prPremiumofiletxtp">Page is not available</p>
                        </div>

                      </>
                    )
                  }
                </>

              ) : (
                !isMobile &&
                <div className="ownseacrgresultpageright">
                  <p className="prPremiumofiletxtp">Sponsor Ad</p>
                  {
                    sponsorAdImage && (moment(sponsorAdImage?.expiryDate, "YYYY-MM-DD").isAfter(moment().startOf("day")) || moment(sponsorAdImage?.expiryDate, "YYYY-MM-DD").isSame(moment().startOf("day"))) ?
                      <div>
                        <a href={sponsorAdImage?.adLinkUrl} target="_blank">
                          <img src={sponsorAdImage?.adImage}
                            style={{
                              maxWidth: "100%"
                            }}
                          />
                        </a>
                      </div> :
                      <div className="boxosimaggedisahdowdiv">
                        <p className="tsttsggsiijxbhnsimag">300x250</p>
                      </div>
                  }
                </div>
              )}
            </div>
          </div>


        </div>
      </section>
      {ownpagesearchkeay && (
        <Ownpagesearchkeywordmodal closemodal={setownpagesearchkeay} result={setSearchedData} />
      )}
    </>
  );
};

export default Blmemberlist;