import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import noicon from "../Images/Refer and Earn/notification.png"
import profilepic from "../Images/profle.png"
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllNotifications } from '../Redux/Slice/NotificationSlice'
import { formatDateAgo } from '../utils/Helper'
import searchhomeprofileimg from "../../src/Images/searchhomeprofileimg.png";
import rewordsIcon from "../../src/Images/Reward.png";
import neooIcon from "../../src/Images/logo/icon.png";
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'

const NotificationBar = ({ show, closeNotifiBar }) => {
    const { AllNotifications, status, error } = useSelector((state) => state.notification);
    const { UserProfileID } = useSelector((state) => state.app);
    const [menuNotificationID, setMenuNotificationID] = useState()
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = (e, id) => {
        e.preventDefault()
        setMenuNotificationID(id)
        // setIsOpen(!isOpen);
        // isOpen ? setIsOpen(true) : setIsOpen(!isOpen)
        setIsOpen(true);
    };

    // const smsnotification = [
    //     { _id: 1, },
    //     { _id: 2, },
    //     { _id: 3, },
    //     { _id: 4, },
    //     { _id: 5, },
    //     { _id: 6, },
    // ];





    const divRef = useRef(null);
    const handleClickOutside = (e) => {
        // Check if the click is outside the referenced div
        if (divRef.current && !divRef.current.contains(e.target)) {
            console.log('Clicked outside of the div');
            // Call your method here
            // alert(1)
            setIsOpen(false)
        }
    };
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    console.log("NOT56", AllNotifications);

    const ViewNotificationMessage = (e, msgText) => {
        e.preventDefault()
        confirmAlert({
            // title: "Confirm to delete",
            // message: `Are you sure you want to delete "${item}"?`,
            title: (
                <h4>Message from Admin</h4>
            ),
            message: (
                <p>
                    {msgText}
                </p>
            ),
            buttons: [
                {
                    label: "Ok",
                    // onClick: () => removeService(indexToRemove),
                    //   style: { background: "#ff0000" },
                },
                // {
                //   label: "No",
                //   onClick: () => alert("Action canceled!"),
                // },
            ],
        });
    }



    return (
        <>
            <section className={`NotificationBar_wrap ${show ? 'show' : ''}`}>
                <div className='top_area'>
                    <h4 className='heading'>Notification</h4>
                    <button className='closebtn' onClick={closeNotifiBar}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className='content_wrap'>

                    {
                        AllNotifications?.length > 0 ? (
                            <>
                                {
                                    AllNotifications.map((item, index) => (

                                        // <div className='smsbox_wrap unread' key={index}>
                                        <div className='smsbox_wrap' key={index}>
                                            <div className='left'>
                                                <div className='profile'>
                                                    {
                                                        item.point ? <img src={rewordsIcon} className='img-fluid' alt='...' style={{width:"40px",height:"40px",padding:"3px"}} /> :
                                                            item.postEnquiryId ? <img src={item?.enqueryByData[0]?.profilePhoto || searchhomeprofileimg} className='img-fluid' alt={item?.enqueryByData[0]?.personName} title={item?.enqueryByData[0]?.personName} /> :
                                                                item.visitedBy ? <img src={item?.visitedByData[0]?.profilePhoto || searchhomeprofileimg} className='img-fluid' alt={item?.visitedByData[0]?.personName} title={item?.visitedByData[0]?.personName} /> :
                                                                    item.businessEnquiryId ? <img src={item?.enqueryByData[0]?.profilePhoto || searchhomeprofileimg} className='img-fluid' alt={item?.enqueryByData[0]?.personName} title={item?.enqueryByData[0]?.personName} /> :
                                                                        <img src={neooIcon} className='img-fluid' alt='...' />
                                                    }


                                                </div>
                                                {/* <p className='text'>
                                                    <b><span>Congratulations!</span> You have earned $27.23 cash reward
                                                        Ashwin Bose,</b> your friend, has joined by your referral code.
                                                    Do more invitations to earn more.
                                                </p> */}
                                                <p className='text'>
                                                    {item.point ? "Reward points collected:" : (item.addedBy === "admin" && item?.notification.length > 50) ? item?.notification?.substring(0, 47) + "..." : item?.notification}
                                                    {
                                                        item.businessPostId && <><br /><Link to={`/ownpostpage/${item?.businessPostId}`}>Click here</Link> to view.</>
                                                    }
                                                    {
                                                        item.postEnquiryId && <><br /><Link to="/myenquiries" state={{ enquiryTab: 2 }}>Click here</Link> to view.</>
                                                    }
                                                    {
                                                        item.businessEnquiryId && <><br /><Link to="/myenquiries" state={{ enquiryTab: 1 }}>Click here</Link> to view.</>
                                                    }
                                                    {
                                                        item.point && <>&nbsp;<strong>{item.point}</strong></>
                                                    }
                                                    {
                                                        item.addedBy === "admin" && item?.notification.length > 50 && <><Link onClick={(e) => ViewNotificationMessage(e, item?.notification)}>read more</Link></>
                                                    }
                                                </p>


                                            </div>
                                            <div className='right'
                                                ref={divRef}
                                            >
                                                {/* <button className='more_btn' onClick={(e) => toggleDropdown(e, item?._id)}>
                                                    <i className="fa-solid fa-ellipsis"></i>
                                                    {isOpen && menuNotificationID === item?._id && (
                                                        <div className='morebtn_dropdown'>
                                                            <ul>
                                                                <li className='item'>
                                                                    <i className="fa-solid fa-trash-can"></i>
                                                                    Delete notification
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </button> */}

                                                <p className='datetime'>{formatDateAgo(item?.createdOn)}</p>
                                                {/* <p className='status'><i className="fa-solid fa-circle"></i></p> */}
                                            </div>
                                        </div>
                                    ))}

                            </>

                        ) : (
                            <>
                                <div className='no_Notification'>
                                    <div className='icon'>
                                        <img src={noicon} className='img-fluid' alt='...' />
                                    </div>

                                    <h4 className='name'>No Notification</h4>
                                    <p className='desc'> We’ll let you know when there will be
                                        something . to update you.</p>

                                </div>
                            </>
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default NotificationBar