import React, { useEffect, useState } from "react";
import vectorarrow from "../../src/Images/vectorarrow.png";
import HttpClient from "../utils/HttpClient";
import { useSelector } from "react-redux";
const Ownpagesearchkeywordmodal = ({ closemodal, result }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [arrowmodalclcik, setarrowmodalclcik] = useState(false);
  const initialData = {
    product: "",
    productCat: "",
    location: "",
    state: "",
    country: "",
  }
  const [filterKey, setFilterKey] = useState(initialData);

  console.log("LLK17", filterKey);


  const handleInsertData = (key, value) => {
    setFilterKey({ ...filterKey, [key]: value });
  };
  const handlearrpwclick = async () => {
    const response = await HttpClient.requestData(
      "filter-business",
      "POST",
      filterKey
    );
    if (response?.status) {
      console.log("SE30", response?.data);
      let resultData = response?.data
      if (viewPremiumMembers || viewVerifiedList) {
        resultData = response?.data.filter((item) => { return item.memberStatus })
      }
      result(resultData);
      closemodal()
    } else {
      console.log("Error while fetching the data ");
    }

    setarrowmodalclcik(!arrowmodalclcik);
  };

  const [countryStates, setCountryStates] = useState([]);
  const getCountryStates = async () => {
    try {
      const res = await HttpClient.requestData(
        "countrywise-state/65f95f070d6753c3bdacccd9",
        "GET"
      );
      if (res?.status) {
        setCountryStates(res.data?.[0]?.states || []);
      }
    } catch (error) {
      console.error("Error fetching country states:", error);
    }
  };

  const [activeDropdown, setActiveDropdown] = useState(null);
  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const [companyServices, setCompanyServices] = useState([]);
  const getCompanyTypeWiseServices = async () => {
    try {
      const res = await HttpClient.requestData(
        `companyType-wise-servicess/${UserProfileID?.companyTypeID}`,
        "GET"
      );
      console.log("RES69E", res.data.map((item) => { return { _id: item._id, name: item.name } }), UserProfileID?.businessProductCat);
      if (res?.status) {

        setCompanyServices(res.data.map((item) => { return { _id: item._id, name: item.name } }))

      }
    } catch (error) {
      console.error("Error occured!", error);
    }
  };

  const [viewAll, setViewAll] = useState(true)
  const [viewPremiumMembers, setViewPremiumMembers] = useState(false)
  const [viewVerifiedList, setViewVerifiedList] = useState(false)

  useEffect(() => {
    getCompanyTypeWiseServices()
    getCountryStates()
  }, [])

  return (
    <div className="uploabannermodalbgshadow ">
      <div className="owenoagedjeywordbgmain">
        <div className="crossdivmainmnm" onClick={() => closemodal()}>
          <i className="fa-solid fa-xmark"></i>
        </div>

        <div className="keywirdrserchttxtdibgflxmain">
          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Keyword</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <input
                type="text"
                value={filterKey?.product}
                onChange={(e) => handleInsertData("product", e.target.value)}
                className="keywprodsecrtinputmain"
                placeholder="Enter keyword"
              />
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Category</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">{filterKey?.productCat || "--- Select ---"}</p>
                <div
                  className="vectorimgamindiv"
                  // onClick={() => setCategoryModel(!categoryModel)}
                  onClick={() => toggleDropdown("services")}
                >
                  <img src={vectorarrow} alt="..." />
                </div>


                {activeDropdown === "services" && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li
                        className="vectoraarowdivomgulmaili"
                        onClick={() => {
                          handleInsertData("productCat", "")
                          toggleDropdown("services");
                        }}
                      >
                        --- Select ---
                      </li>
                      {companyServices.map((service, index) => (
                        <li
                          key={index}
                          className="vectoraarowdivomgulmaili"
                          onClick={() => {
                            // handleInputChange("state", state);
                            handleInsertData("productCat", service?.name)
                            toggleDropdown("services");
                          }}
                        >
                          {service?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Page</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="">
                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                    onClick={() => { setViewAll(!viewAll) }}
                    checked={viewAll}
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    All
                  </label>
                </div>

                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                    onClick={() => { setViewPremiumMembers(!viewPremiumMembers) }}
                    checked={viewPremiumMembers}
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    Premium Members
                  </label>
                </div>

                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                    onClick={() => { setViewVerifiedList(!viewVerifiedList) }}
                    checked={viewVerifiedList}
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    Verified list
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Location</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <input
                type="text"
                value={filterKey?.location}
                onChange={(e) =>
                  setFilterKey({ ...filterKey, location: e.target.value })
                }
                className="keywprodsecrtinputmain"
                placeholder="Enter address"
              />
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">State</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">{filterKey?.state || "--- Select ---"}</p>
                <div
                  className="vectorimgamindiv"
                  // onClick={() => setStateModel(!stateModel)}
                  onClick={() => toggleDropdown("state")}
                >
                  <img src={vectorarrow} alt="..." />
                </div>
                {activeDropdown === "state" && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li
                        className="vectoraarowdivomgulmaili"
                        onClick={() => {
                          handleInsertData("state", "")
                          toggleDropdown("state");
                        }}
                      >
                        --- Select ---
                      </li>
                      {countryStates.map((state, index) => (
                        <li
                          key={index}
                          className="vectoraarowdivomgulmaili"
                          onClick={() => {
                            // handleInputChange("state", state);
                            handleInsertData("state", state?.name)
                            toggleDropdown("state");
                          }}
                        >
                          {state?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Country</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">{filterKey?.country === "65f95f070d6753c3bdacccd9" ? "United Arab Emirates" : "--- Select ---"} </p>
                <div
                  className="vectorimgamindiv"
                  // onClick={() => setCountryModel(!countryModel)}
                  onClick={() => toggleDropdown("country")}
                >
                  <img src={vectorarrow} alt="..." />
                </div>
                {activeDropdown === "country" && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li
                        className="vectoraarowdivomgulmaili"
                        onClick={() => {
                          handleInsertData("country", "")
                          toggleDropdown("country");
                        }}
                      >
                        --- Select ---
                      </li>

                      <li className="vectoraarowdivomgulmaili"
                        onClick={() => {
                          // handleInputChange("state", state);
                          handleInsertData("country", "65f95f070d6753c3bdacccd9")
                          toggleDropdown("country");
                        }}
                      >
                        United Arab Emirates
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="cancelbscearchbtntndiv">
            <button className="cancelbtn" 
            onClick={() => {
              setFilterKey(initialData)
              setViewAll(true)
              setViewPremiumMembers(false)
              setViewVerifiedList(false)
            }}
            >Cancel</button>
            <button className="scearchbtn" onClick={handlearrpwclick}>Search</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ownpagesearchkeywordmodal;
