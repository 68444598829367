import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ResponsiveSearch from "../Component/ResponsiveSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchModal,
  getOwnProfile,
  responsiveSearch,
} from "../Redux/Slice/PostSlice";
import HttpClient from "../utils/HttpClient";
import SearchBarmodal from "../Component/SearchBarmodal";

const FixedFooter = () => {
  const location = useLocation();
  const [homePageUrl, sethomePageUrl] = useState("");
  const [params, setparams] = useState("");
  const [hanndleglass, SetHandleglass] = useState(false);
  const { responSiveSearchBar, UserLocationLink, SearchBar, UserProfileID } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();

  // console.log(UserLocationLink, "UserLocationLink");
  console.log(UserProfileID, "UserLocationLink");

  const getUserProfile = async () => {
    const res = await HttpClient.requestData("get-profile", "GET", {});
    console.log(res?.data?.memberStatus, "user profile");
    if (res && res?.status) {
      setparams(res?.data?.personName);
      //   setprofilePic(res?.data);

      if (res?.data?.memberStatus) {
        sethomePageUrl("/profile/");
      } else {
        sethomePageUrl("/NonMemberuserprofile/");
      }
    }
  };

  const handleglassClick = (e) => {
    e.stopPropagation();
    SetHandleglass(!hanndleglass);
    // dispatch(responsiveSearch(!responSiveSearchBar));
    dispatch(SearchModal(!SearchBar));
    // dispatch(SearchModal(!SearchBar));
    // dispatch(SearchModal(!SearchBar));
  };

  useEffect(() => {
    getUserProfile();
  }, []);
  useEffect(() => {
    dispatch(getOwnProfile());
  }, []);
  return (
    <>
      <div className={location.pathname === '/chat' ? 'hiddenfooter' : ''}>
      <section className="FixedFootSec">
        <div className="cust_container">
          <div className="FixFootMenu">
            <Link
              to={homePageUrl + params}
              onClick={() => dispatch(responsiveSearch(false))}
            >
              <div className="footMenuIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 27 25"
                  fill="none"
                >
                  <path d="M16.6628 24.0005C16.6628 21.6978 16.6628 19.5485 16.6628 17.3993C16.6628 15.8641 16.5607 15.7106 15.0301 15.7106C13.9077 15.7106 12.7852 15.7106 11.6628 15.7106C10.7444 15.7106 10.4383 16.12 10.4383 16.9899C10.4383 18.8833 10.4383 20.7767 10.4383 22.6701C10.4383 23.0794 10.3873 23.4888 10.3873 24.0005C8.65256 24.0005 7.07093 24.0517 5.43828 24.0005C4.36685 23.9494 3.90767 23.233 3.90767 22.2095C3.90767 19.702 3.90767 17.1946 3.90767 14.6359C3.90767 13.459 3.60154 12.7426 2.27501 12.589C0.948483 12.3843 0.54032 10.9515 1.56073 10.0816C5.18318 6.80652 8.80563 3.63381 12.4791 0.461104C13.1424 -0.101796 13.9077 -0.152968 14.5709 0.461104C18.1934 3.63381 21.8668 6.80652 25.4893 10.0304C25.8464 10.3374 26.1526 11.1562 25.9995 11.5144C25.8464 11.975 25.1832 12.4355 24.673 12.589C23.2444 12.9472 22.9383 13.1519 22.9383 14.6871C22.9383 17.1434 22.9383 19.6509 22.9383 22.1072C22.9383 23.54 22.4791 24.0005 21.0505 24.0005C19.673 24.0005 18.2954 24.0005 16.6628 24.0005Z" />
                </svg>
                <span className="footmenuicon_name">Home</span>
              </div>
            </Link>

            <div className="footMenuIcon" onClick={(e) => handleglassClick(e)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z" />
              </svg>
              <span className="footmenuicon_name">Search</span>
            </div>

            <Link
              to="/OwnContactlist"
              onClick={() => dispatch(responsiveSearch(false))}
            >
              <div className="footMenuIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  viewBox="0 0 40.000000 40.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path d="M155 370 c-35 -39 7 -99 61 -86 14 4 30 17 36 31 25 56 -54 102 -97 55z m75 -19 c7 -15 6 -23 -6 -35 -19 -20 -29 -20 -48 0 -20 19 -5 54 24 54 11 0 24 -9 30 -19z" />
                    <path d="M73 288 c-18 -19 -36 -48 -39 -66 -8 -37 11 -45 20 -8 3 14 18 38 32 55 36 44 25 59 -13 19z" />
                    <path d="M290 302 c0 -5 11 -19 24 -32 14 -12 28 -35 31 -51 8 -36 25 -48 25 -18 0 30 -47 109 -65 109 -8 0 -15 -4 -15 -8z" />
                    <path d="M15 130 c-39 -43 15 -107 70 -82 13 6 27 23 31 37 13 54 -62 88 -101 45z m73 -31 c4 -31 -19 -44 -49 -29 -22 12 -24 31 -7 48 21 21 53 10 56 -19z" />
                    <path d="M294 125 c-35 -53 27 -106 81 -71 30 20 33 56 7 79 -28 26 -69 21 -88 -8z m75 -9 c23 -28 -17 -68 -45 -45 -15 12 -19 41 -7 52 11 12 40 8 52 -7z" />
                    <path d="M124 29 c-12 -19 118 -27 146 -9 8 5 13 10 10 11 -22 5 -153 3 -156 -2z" />
                  </g>
                </svg>
                <span className="footmenuicon_name">Directory</span>
              </div>
            </Link>

            {UserProfileID?.memberStatus && (
              <Link
                // to={UserLocationLink?.locationLink}
                to="/reward"
                target="_blank"
                onClick={() => dispatch(responsiveSearch(false))}
              >
                <div className="footMenuIcon">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.75 3.75c0 .844.279 1.623.75 2.25H2.75A1.75 1.75 0 001 7.75v2.5c0 .698.409 1.3 1 1.582v8.418c0 .966.784 1.75 1.75 1.75h16.5A1.75 1.75 0 0022 20.25v-8.418c.591-.281 1-.884 1-1.582v-2.5A1.75 1.75 0 0021.25 6H19.5a3.75 3.75 0 00-3-6c-1.456 0-3.436.901-4.5 3.11C10.936.901 8.955 0 7.5 0a3.75 3.75 0 00-3.75 3.75zM11.22 6c-.287-3.493-2.57-4.5-3.72-4.5a2.25 2.25 0 000 4.5h3.72zm9.28 6v8.25a.25.25 0 01-.25.25h-7.5V12h7.75zm-9.25 8.5V12H3.5v8.25c0 .138.112.25.25.25h7.5zm10-10a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-8.5v3h8.5zm-18.5 0h8.5v-3h-8.5a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25zm16-6.75A2.25 2.25 0 0116.5 6h-3.72c.287-3.493 2.57-4.5 3.72-4.5a2.25 2.25 0 012.25 2.25z"
                    />
                  </svg>
                  {/* <i className="fa-solid fa-gift"></i> */}

                  <span className="footmenuicon_name">Rewards</span>
                </div>
              </Link>
            )}

            <Link
              to="/masterdashbord"
              onClick={() => dispatch(responsiveSearch(false))}
            >
              <div className="footMenuIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <g clip-path="url(#clip0_705_1518)">
                    <path d="M13.6781 0C14.2652 0 14.7935 0 15.3806 0C15.4393 0 15.5567 0.0587045 15.6154 0.0587045C16.3785 0.176113 17.2004 0.234818 17.9636 0.410931C25.419 2.17206 30.2328 9.68623 28.7652 17.2004C28.1781 20.0769 26.8866 22.6012 24.832 24.6559C22.6599 26.8279 20.0769 28.2368 17.0243 28.7652C16.496 28.8826 15.9089 28.9413 15.3806 29C14.7935 29 14.2652 29 13.6781 29C13.2672 28.9413 12.915 28.9413 12.504 28.8826C9.62753 28.4717 7.10324 27.3563 4.93117 25.419C2.40688 23.1883 0.821862 20.3704 0.234818 17.0243C0.117409 16.4372 0.0587045 15.9089 0 15.3806C0 14.7935 0 14.2652 0 13.6781C0 13.5607 0.0587045 13.502 0.0587045 13.3846C0.117409 12.8563 0.176113 12.3279 0.293522 11.7996C0.821862 8.92308 2.17206 6.39879 4.22672 4.34413C6.39879 2.17206 8.98178 0.763158 12.0344 0.234818C12.5628 0.117409 13.0911 0.0587045 13.6781 0ZM24.1862 22.836C28.3543 18.1397 28.7065 10.0972 23.0709 4.98988C17.4352 -0.117409 8.80567 0.763158 4.28543 6.80972C0 12.5628 1.70243 19.5486 4.87247 22.7773C5.2834 21.1923 6.04656 19.7834 7.10324 18.5506C8.21862 17.3178 9.51012 16.4372 11.0951 15.8502C7.80769 13.4433 7.74899 9.2753 9.86235 6.86842C12.0931 4.28543 15.9089 3.9919 18.4332 6.10526C19.666 7.16194 20.4291 8.51215 20.6053 10.0972C20.8401 12.504 19.9008 14.3826 18.0223 15.7915C21.1336 17.2004 23.247 19.4899 24.1862 22.836ZM14.7348 27.2976C15.0283 27.2976 15.5567 27.2389 16.085 27.1802C18.4919 26.8866 20.664 25.9474 22.6012 24.4211C22.6599 24.3623 22.7186 24.2449 22.7186 24.1275C22.0729 19.6073 17.9636 16.496 13.502 17.083C9.80364 17.6113 6.92713 20.4291 6.39879 24.1275C6.39879 24.2449 6.45749 24.3623 6.51619 24.4211C8.80567 26.2996 11.5061 27.2976 14.7348 27.2976ZM14.4413 15.2632C16.8482 15.3219 18.8441 13.3846 18.9028 10.919C18.9615 8.57085 17.0243 6.51619 14.6174 6.45749C12.2105 6.39879 10.1559 8.33603 10.0972 10.8016C10.0385 13.2085 12.0344 15.2045 14.4413 15.2632Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_705_1518">
                      <rect width="29" height="29" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="footmenuicon_name">Dashboard</span>
              </div>
            </Link>
          </div>
        </div>
      </section>
      </div>

      {/* {responSiveSearchBar && <ResponsiveSearch />} */}
      {/* {responSiveSearchBar && <SearchBarmodal />} */}
      {SearchBar && <SearchBarmodal />}
    </>
  );
};

export default FixedFooter;
