import React, { useContext, useEffect, useState } from 'react'
import EditProfileblm from './EditProfileblm';
import Blmpostadd from './Blmpostadd';
import Viewersengagement from './Viewersengagement';
import Upgradeprofile from './Upgradeprofile';
import Contactdetaiilsprofile from './Contactdetaiilsprofile';
import Allpostownpage from './Allpostownpage';
import Allpostpage from './Allpostpage';
import { useSelector } from "react-redux";
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import { Link } from 'react-router-dom';

const Ownpostpage = () => {
    const { SearchBar, ProfileSubmenuDropDownToggle, UserProfileID, SingleUserData } =
        useSelector((state) => state.app);
    const colordata = useContext(Colorcontext);
    const [tabpostall, setTabpostall] = useState(1)
    
    useEffect(() => {
        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);

    useSelector((state) => state.app);

    const [isMobile, setIsMobile] = useState(false);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // console.log("SRC40", isMobile);


    const postdicclickbtn = (tabnumber) => {
        setTabpostall(tabnumber)
    }
    return (
        <section className='ownblmprofiledivmaoin'>
            <div className='cust_container'>
                <div className='ownblmprofiledivflx'>
                    {
                        isMobile ?
                            <>

                                {/* Displaying Posts */}
                                <div className='ownblmprofiledivright'>
                                    <div className='allpostmyapooskducvsdic'>
                                        <div className='allpostmyapooskducvsdictop'>
                                            <div className='postamtydposdivflx'>
                                                <button className={tabpostall === 1 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(1)}>All Post</button>
                                                <button className={tabpostall === 2 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(2)}>My Post</button>
                                            </div>
                                        </div>
                                        <div className='allpostmyapooskducvsdicbottom'>
                                            {tabpostall === 1 && (
                                                <>
                                                    <Allpostpage />
                                                </>
                                            )}
                                            {tabpostall === 2 && (
                                                <>
                                                    <Allpostownpage />
                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>

                                <div className='ownblmprofiledivleft'>
                                    <div className='repsonsivedivcls'>
                                        {/* For mobile responsive code start  */}
                                        <div className=' oenblmsnfidivmainleft'>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                                {/* <EditProfileblm /> */}
                                                <EditProfileblm data={UserProfileID} />
                                            </div>

                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                                <Upgradeprofile />
                                            </div>



                                        </div>
                                        <div className='oenblmsnfidivmainright'>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                                <Contactdetaiilsprofile data={UserProfileID} />
                                            </div>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                                <Blmpostadd />
                                            </div>


                                        </div>
                                        {/* For mobile responsive code  end */}
                                    </div>


                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        {/* <EditProfileblm /> */}
                                        <EditProfileblm data={UserProfileID} />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Blmpostadd />
                                    </div>

                                    <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                                        <div className='bmlmmaposttaddddib'>
                                            <div className='bmlmmaposttaddddibdivmain'>
                                                <div className='poisyybtnmainlibkldivflx'>
                                                    <Link className="_direxrrcttstbtn ipyysffraedtn"
                                                        style={{
                                                            margin: "0 auto",
                                                            color: "#fff"
                                                        }}
                                                        to="/myenquiries"
                                                    >
                                                        View Enquiries
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Upgradeprofile />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Contactdetaiilsprofile
                                            data={UserProfileID}
                                        />
                                    </div>



                                </div>



                            </>
                            :
                            <>
                                <div className='ownblmprofiledivleft'>
                                    <div className='repsonsivedivcls'>
                                        {/* For mobile responsive code start  */}
                                        <div className=' oenblmsnfidivmainleft'>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                                {/* <EditProfileblm /> */}
                                                <EditProfileblm data={UserProfileID} />
                                            </div>

                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                                <Upgradeprofile />
                                            </div>



                                        </div>
                                        <div className='oenblmsnfidivmainright'>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain'>
                                                <Contactdetaiilsprofile data={UserProfileID} />
                                            </div>
                                            <div className='oenblmsnfdrpbapbbtm responsivdedivmain '>
                                                <Blmpostadd />
                                            </div>


                                        </div>
                                        {/* For mobile responsive code  end */}
                                    </div>


                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        {/* <EditProfileblm /> */}
                                        <EditProfileblm data={UserProfileID} />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Blmpostadd />
                                    </div>

                                    <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                                        <div className='bmlmmaposttaddddib'>
                                            <div className='bmlmmaposttaddddibdivmain'>
                                                <div className='poisyybtnmainlibkldivflx'>
                                                    <Link className="_direxrrcttstbtn ipyysffraedtn"
                                                        style={{
                                                            margin: "0 auto",
                                                            color: "#fff"
                                                        }}
                                                        to="/myenquiries"
                                                    >
                                                        View Enquiries
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Upgradeprofile />
                                    </div>

                                    <div className='oenblmsnfdrpbapbbtm deslrtopwdijjsmain'>
                                        <Contactdetaiilsprofile
                                            data={UserProfileID}
                                        />
                                    </div>



                                </div>

                                {/* Displaying Posts */}
                                <div className='ownblmprofiledivright'>
                                    <div className='allpostmyapooskducvsdic'>
                                        <div className='allpostmyapooskducvsdictop'>
                                            <div className='postamtydposdivflx'>
                                                <button className={tabpostall === 1 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(1)}>All Post</button>
                                                <button className={tabpostall === 2 ? "poisttsdpostdibbtn actv " : "poisttsdpostdibbtn"} onClick={() => postdicclickbtn(2)}>My Post</button>
                                            </div>
                                        </div>
                                        <div className='allpostmyapooskducvsdicbottom'>
                                            {tabpostall === 1 && (
                                                <>
                                                    <Allpostpage />
                                                </>
                                            )}
                                            {tabpostall === 2 && (
                                                <>
                                                    <Allpostownpage />
                                                </>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </>
                    }



                </div>
            </div>
        </section>
    )
}

export default Ownpostpage
