import React, { useContext, useEffect } from 'react'
import bannerlogin from "../../Images/bannerlogin.png"
import Chooseplan from './Chooseplan'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import { Link } from 'react-router-dom';
const SearchLogin = () => {
    const colordata = useContext(Colorcontext);
    useEffect(() => {
        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <section className='searchloginmain'>
            <div className='cust_container'>
                <div className='bg-wht'>
                    <div className='bannerloginbg'>
                        <img src={bannerlogin} alt='...' />
                        <div className='bannerloginbgcontent'>
                            <h4 className='busineeheadpara'>Your business directory
                                for local search</h4>
                            <p className='businesssubparapara'>
                                List your business in the Neoo Search directory and smartly connect your customers
                            </p>
                            <Link className="freebusinessbtn btn-rth" to="/businessdirectory">
                                Learn more
                            </Link>
                        </div>
                    </div>
                    <div className=''>
                        <Chooseplan />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SearchLogin
