import React, { useEffect, useRef, useState } from 'react';
import crosssimgiocn from "../../Images/crosssimgiocn.png"
import Neoologoasjji from "../../Images/Neoologoasjji.png";
// import postbanneimmhg from "../../Images/postbanneimmhg.png";
import postbanneimmhg from "../../Images/cameralogo.png";
import prodlikeicon from "../../Images/prodlikeicon.png";
import prodlikedicon from "../../Images/liked.png";
import commenytstproodiuificon from "../../Images/commenytstproodiuificon.png";
import msgiocn from "../../Images/msgiocn.png";
import Photography from "../../Images/Photography.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import cntnctimg3 from "../../Images/cntnctimg3.png";
import cmtprofiletwoimg from "../../Images/cmtprofiletwoimg.png"
import Ownpostpagemodal from '../../Modal/Ownpostpagemodal';
import HttpClient from '../../utils/HttpClient';
import { formatDateAgo } from '../../utils/Helper';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllBusinessPosts, LoadBusinessPostsInBackground } from '../../Redux/Slice/BusinessPostSlice';
import ItemLoader from '../../Component/ItemLoader';
import PostComments from './PostComments';
import PostEnqueyModal from '../../Modal/PostEnqueyModal';
import { getSingleUserProfile, ShareModalonOff } from '../../Redux/Slice/PostSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ProfileShare from '../../Modal/ProfileShare';

const Allpostpage = () => {
    const { status, AllBusinessPosts } = useSelector((state) => state.post);
    const { ShareModalOpenClose, UserProfileID, SingleUserData } = useSelector((state) => state.app);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    console.log("GGS31", AllBusinessPosts);


    const [loadmtersdiumodal, setLoadmtersdiumodal] = useState(false);
    const [handleownpostmodal, sethandleownpostmodal] = useState(false)
    const loadmoresfgbtbnbclokj = () => {
        setLoadmtersdiumodal(!loadmtersdiumodal)
    }
    const [threedpotsspclmodal, setThreedpotsspclmodal] = useState(true);
    const [ownpostcommetclickmodal, setownpostcommetclickmodal] = useState(false)

    const [menuPostID, setMenuPostID] = useState()
    const threedotssettingclcil = (e, id) => {
        e.preventDefault()
        setMenuPostID(id)
        threedpotsspclmodal ? setThreedpotsspclmodal(true) : setThreedpotsspclmodal(!threedpotsspclmodal)
    }

    const [commentPostID, setCommentPostID] = useState()
    const ownposcommentclcik = (e, id) => {
        e.preventDefault()
        setCommentPostID(id)
        ownpostcommetclickmodal ? setownpostcommetclickmodal(true) : setownpostcommetclickmodal(!ownpostcommetclickmodal)
        // setownpostcommetclickmodal(true)
        // setThreedpotsspclmodal(!threedpotsspclmodal)
    }

    const [queryPostID, setQueryPostID] = useState()
    const [sendQueryModal, setSendQueryModal] = useState(false)
    const handleQuery = (e, id, userId) => {
        e.preventDefault()
        setQueryPostID(id)
        UserProfileID?._id !== userId && setSendQueryModal(!sendQueryModal)
    }

    const [selectedPostData, setSelectedPostData] = useState()
    const handleopwmnpostmodal = (e, item) => {
        e.preventDefault()
        setSelectedPostData(item)
        sethandleownpostmodal(!handleownpostmodal)
    }

    //Display Posts
    const [allData, setAllData] = useState([])

    const [visibleItems, setVisibleItems] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(3);
    const loadMoreItems = 1;

    const handleLoadMore = () => {
        // Load more items by increasing the itemsToShow count
        setItemsToShow((prev) => prev + loadMoreItems);
    };

    useEffect(() => {
        // getMyBusinessPosts()
        // params.id && !UserProfileID ? navigate('/user-login') :
        UserProfileID ? dispatch(fetchAllBusinessPosts()) : navigate('/user-login')

    }, [])

    useEffect(() => {
        // setVisibleItems(AllBusinessPosts.slice(0, itemsToShow));

        setVisibleItems(
            [...AllBusinessPosts]
                .sort((a, b) => (b._id === params?.id ? 1 : 0) - (a._id === params?.id ? 1 : 0))
                .slice(0, itemsToShow)
        );


    }, [itemsToShow, AllBusinessPosts]);
    console.log("PARAMS104", params?.id, visibleItems);

    // const [likeCounter, setLikeCounter] = useState(0)
    // const [isLikeByMe, setIsLikeByMe] = useState(false)
    // const [activePostId, setActivePostId] = useState("")



    const handlePostLike = async (e, data) => {
        e.preventDefault()
        if (UserProfileID?._id === data?.profileId) return
        try {
            const response = await HttpClient.requestData(
                "business-post-like",
                "POST",
                { postId: data?._id }
            );
            if (response.status) {
                // Navigate to payment page
                // Navigate("/searchhome");
                // Navigate("/");
                // dispatch(getOwnProfile())

                // setActivePostId(data?._id)

                // setIsLikeByMe(!isLiked)

                // setLikeCounter(isLiked ? likeCounter - 1 : likeCounter + 1)

                // if(data?.PostLikeData.some(item => item.likedBy === UserProfileID?._id)){
                //     setIsLikeByMe(true)
                //     setLikeCounter(likeCounter - 1)
                // }else{
                //     setIsLikeByMe(false)
                //     setLikeCounter(likeCounter + 1)
                // }



                console.log("RESP", response, data);

            } else {
                console.error("Error in Like/Dislike");
                console.log("RESP", response, data);
            }
        } catch (error) {
            console.error("Error submitting Like/Dislike:", error);
            console.log("RESP", error, data);
        } finally {
            dispatch(LoadBusinessPostsInBackground())
        }
    }

    const gotoBusinessListing = (e, user) => {
        e.preventDefault()
        dispatch(getSingleUserProfile(user?._id))
        navigate(`/businesspage/${user?.companyName?.toLowerCase().replace(/\s+/g, "_")}`)

        // alert(userId)
    }

    const hidePost = async(e, postId) => {
        e.preventDefault()
        // setThreedpotsspclmodal(false)
        try {
            const response = await HttpClient.requestData(
                `hide-post/${postId}`,
                "PUT",
            );
            if (response.status) {
                toast.success(response?.message)
            } else {
                console.error("Error in Like/Dislike");
            }
        } catch (error) {
            console.error("Error submitting Like/Dislike:", error);
        } finally {
            dispatch(LoadBusinessPostsInBackground())
        }

    }

    const location = useLocation();
    const copyPostLink = (e, postId) => {
        e.preventDefault()
        console.log("location166", location);
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        navigator.clipboard
            .writeText(postUrl)
            .then(() => {
                // alert("Copied")
                toast.success("Copied Successfully");
                setThreedpotsspclmodal(false)
                // setCopied(true);
                // setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            })
            .catch((err) => console.error('Failed to copy text: ', err));
    }
    const [sharemodal, setSharemodal] = useState(false);
    const [postLink, setPostLink] = useState("")
    const shareClick = (e, postId) => {
        e.preventDefault()
        const postUrl = `${window.location.origin}/ownpostpage/${postId}`;
        setPostLink(postUrl)
        setSharemodal(!sharemodal);
        dispatch(ShareModalonOff(true));
    };

    const divRef = useRef(null);
    const handleClickOutside = (e) => {
        // Check if the click is outside the referenced div
        if (divRef.current && !divRef.current.contains(e.target)) {
            console.log('Clicked outside of the div');
            // Call your method here
            // alert(1)
            setThreedpotsspclmodal(false)
        }
    };
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            {
                status === "loading" ? (
                    <>
                        <ItemLoader />
                    </>
                ) : (
                    <>
                        <div className='allpostowndmaimndivmain'>
                            {
                                visibleItems.map((post, index) => {
                                    return (
                                        <>
                                            {/* Single Post Start */}
                                            <div className='allpostowndmaimndiv'
                                            // onClick={(e)=>handleopwmnpostmodal(e,post)}
                                            >
                                                <div className='allpostowndmaimndivallelemnt'>
                                                    <div className='allpostimagedesuigfnatinob'
                                                        onClick={(e) => gotoBusinessListing(e, post?.userData)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <div className='allposttslodjjimg'>
                                                            <img src={post?.userData?.businessProfilePic || Neoologoasjji} alt='...' />
                                                        </div>
                                                        <div className=''>
                                                            <h4 className='neeporoalposttsheadr'>{post?.userData?.companyName}</h4>
                                                            {/* <p className='cmskshhstdiifgysyttxtpop'><span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p> */}
                                                            <p className='cmskshhstdiifgysyttxtpop'>{post?.userData?.businessProductCat?.join(" | ")}</p>
                                                        </div>
                                                    </div>
                                                    <div className='crosasddoproidikdsjbhidivflx'>
                                                        <div className='threediostyiicpnggsuc'>
                                                            <div className='threediostyiicpn' onClick={(e) => threedotssettingclcil(e, post?._id)}>
                                                                <i className="fa-solid fa-ellipsis"></i>
                                                            </div>
                                                            {(threedpotsspclmodal && menuPostID === post?._id) &&
                                                                <div className='threedotssettingdiv'
                                                                ref={divRef}
                                                                >
                                                                    <ul className="userMenu">
                                                                        <li>
                                                                            <a
                                                                                href="javascript:"
                                                                                onClick={(e) => copyPostLink(e, post?._id)}
                                                                            >
                                                                                <i className="fa-solid fa-link mr-2"></i>
                                                                                Copy post link
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                href="javascript:"
                                                                                onClick={(e) => shareClick(e, post?._id)}
                                                                            >

                                                                                <i className="fa-solid fa-share-nodes  mr-2"></i>
                                                                                Share post link
                                                                            </a>
                                                                        </li>
                                                                        {/* <li>
                                                                            <a
                                                                            >

                                                                                <i className="fa-regular fa-bookmark mr-2"></i>
                                                                                Save post
                                                                            </a>
                                                                        </li>

                                                                        <li>
                                                                            <a
                                                                            >
                                                                                <i className="fa-solid fa-eye-slash mr-2"></i>
                                                                                Not interested
                                                                            </a>
                                                                        </li> */}
                                                                    </ul>
                                                                </div>}
                                                        </div>
                                                        {
                                                            post?.userData?._id === UserProfileID?._id &&
                                                            <div className='crossidovpostimgdiv'
                                                                onClick={(e) => hidePost(e, post?._id)}
                                                            >
                                                                <img src={crosssimgiocn} alt='...' />
                                                                {/* {post?._id} */}
                                                            </div>
                                                        }



                                                    </div>
                                                </div>
                                                <div className='postbannettimhdivflxmakflx'>
                                                    <div className='postbanneimmhgdivjakhjimh'
                                                        onClick={(e) => handleopwmnpostmodal(e, post)}
                                                    >
                                                        <img src={post?.file || postbanneimmhg} alt='' style={{ borderRadius: "12px", cursor: "pointer" }} />
                                                    </div>
                                                    <div className='poosttalouindtdettstctpopdoiv'
                                                        onClick={(e) => handleopwmnpostmodal(e, post)}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        <p className='postimagedesrcptoioponttxp'>
                                                            {post?.description || "..."}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <p className='htrrstrtdttgsgbtxttppgh'>{formatDateAgo(post?.createdOn)}</p>
                                                </div>
                                                <div className="allpostlikecommetsendenqeryudiv">
                                                    <div className="prodctlikecmntenquerypagediv">
                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd"
                                                            onClick={(e) => { handlePostLike(e, post) }}
                                                        >
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={post?.PostLikeData.some(item => item.likedBy === UserProfileID?._id) ? prodlikedicon : prodlikeicon}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">
                                                                {/* Like <span>{activePostId === post._id ? likeCounter : post?.PostLikeCount}</span> */}
                                                                Like <span>{post?.PostLikeCount}</span>
                                                            </p>
                                                        </div>

                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={(e) => ownposcommentclcik(e, post?._id)}>
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={commenytstproodiuificon}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">Comment</p>
                                                        </div>

                                                        {sendQueryModal &&
                                                            <><PostEnqueyModal closemodal={setSendQueryModal} post={queryPostID} /></>
                                                        }


                                                        <div className="produucvtsdlikeinmncomnfdivvhjjd"
                                                            onClick={(e) => handleQuery(e, post?._id, post?.userData?._id)}
                                                        >
                                                            <div className="produucvtsdlikeinmncomnf">
                                                                <img
                                                                    src={msgiocn}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                                        </div>
                                                    </div>
                                                    {ownpostcommetclickmodal && commentPostID === post?._id &&
                                                        // <div className='ownpostcommentdiv'>
                                                        //     {
                                                        //         UserProfileID._id !== post?.profileId &&
                                                        //         <div className='ownpostcommentdivmain'>
                                                        //             <div className="cntnctimgdivnamin">
                                                        //                 <img src={UserProfileID?.profilePhoto || cntnctimg3} alt={UserProfileID?.personName || "..."} />
                                                        //             </div>

                                                        //             <div className='textareadibmainmainbrdr'>
                                                        //                 <div className='textareadibmain'>
                                                        //                     <textarea rows={4} cols={4} className='textareadibmainrerare'
                                                        //                         placeholder='Write a Comment'
                                                        //                         onChange={(e) => setCommentText(e.target.value)}
                                                        //                         value={commentText || ""}
                                                        //                     />
                                                        //                 </div>
                                                        //                 <div className="mesagsendidvmain"
                                                        //                     onClick={(e) => handleCommentSend(e, post?._id)}>
                                                        //                     <i class="fa-solid fa-paper-plane" style={{ cursor: "pointer" }}></i>
                                                        //                 </div>
                                                        //             </div>

                                                        //         </div>
                                                        //     }

                                                        //     {
                                                        //         post?.PostCommentData.length>0 &&
                                                        //         post?.PostCommentData.map((comment, index) => {
                                                        //             return (
                                                        //                 <>
                                                        //                     <div className='ownpostothercommentdivmain'>
                                                        //                         <div className='profikellcmnjhhrrddivflxttst'>
                                                        //                             <div className='profikellcmntimgdivflxttst'>
                                                        //                                 <div className='commentproofilleimg'>
                                                        //                                     <img src={comment?.commentedBy[0]?.profilePhoto || cmtprofiletwoimg} alt='...' />
                                                        //                                 </div>
                                                        //                                 <p className='commporosttxttnamepp'>{comment?.commentedBy[0]?.personName}</p>
                                                        //                             </div>
                                                        //                             <p className='commporosttxttnamepp'>{formatDateAgo(comment?.createdOn)}</p>
                                                        //                         </div>
                                                        //                         <p className='commsoprpodttctxppp'>
                                                        //                             {comment?.comment}
                                                        //                         </p>
                                                        //                     </div>
                                                        //                 </>
                                                        //             )
                                                        //         })
                                                        //     }
                                                        //     {/* <div className='ownpostothercommentdivmain'>
                                                        //         <div className='profikellcmnjhhrrddivflxttst'>
                                                        //             <div className='profikellcmntimgdivflxttst'>
                                                        //                 <div className='commentproofilleimg'>
                                                        //                     <img src={cmtprofiletwoimg} alt='...' />
                                                        //                 </div>
                                                        //                 <p className='commporosttxttnamepp'>John Doe</p>
                                                        //             </div>
                                                        //             <p className='commporosttxttnamepp'>1 hour ago</p>
                                                        //         </div>
                                                        //         <p className='commsoprpodttctxppp'>
                                                        //             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                                        //         </p>
                                                        //     </div> */}

                                                        // </div>
                                                        <PostComments data={post?.PostCommentData} postProfileId={post?.profileId} postId={post?._id} />
                                                    }
                                                </div>
                                            </div>
                                            {/* //Single Post End */}
                                        </>
                                    )
                                })
                            }


                            {loadmtersdiumodal &&
                                <>
                                </>
                                // <div className=''>
                                //     <div className='allpostowndmaimndiv'>
                                //         <div className='allpostowndmaimndivallelemnt'>
                                //             <div className='allpostimagedesuigfnatinob'>
                                //                 <div className='allposttslodjjimg'>
                                //                     <img src={Neoologoasjji} alt='...' />
                                //                 </div>
                                //                 <div className=''>
                                //                     <h4 className='neeporoalposttsheadr'>Neoo Search FZE - Advertising & Marketing</h4>
                                //                     <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                                //                 </div>
                                //             </div>
                                //             <div className='crosasddoproidikdsjbhidivflx'>
                                //                 <div className='threediostyiicpn'>
                                //                     <i className="fa-solid fa-ellipsis"></i>
                                //                 </div>

                                //                 <div className='crossidovpostimgdiv'>
                                //                     <img src={crosssimgiocn} alt='...' />
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         <div className='postbannettimhdivflxmakflx'>
                                //             <div className='postbanneimmhgdivjakhjimh'>
                                //                 <img src={postbanneimmhg} alt='...' />
                                //             </div>
                                //             <div className='poosttalouindtdettstctpopdoiv'>
                                //                 <p className='postimagedesrcptoioponttxp'>
                                //                     Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                                //                 </p>
                                //             </div>
                                //         </div>
                                //         <div className=''>
                                //             <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                                //         </div>
                                //         <div className="allpostlikecommetsendenqeryudiv">
                                //             <div className="prodctlikecmntenquerydiv">
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={prodlikeicon}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">
                                //                         Like <span>100</span>
                                //                     </p>
                                //                 </div>
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik} >
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={commenytstproodiuificon}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                                //                 </div>
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={msgiocn}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                //                 </div>
                                //             </div>

                                //             {ownpostcommetclickmodal &&
                                //                 <div className='ownpostcommentdiv'>
                                //                     <div className='ownpostcommentdivmain'>
                                //                         <div className="cntnctimgdivnamin">
                                //                             <img src={cntnctimg3} alt='...' />
                                //                         </div>

                                //                         <div className='textareadibmainmainbrdr'>
                                //                             <div className='textareadibmain'>
                                //                                 <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                //                             </div>
                                //                             <div className="mesagsendidvmain">
                                //                                 <i class="fa-solid fa-paper-plane"></i>
                                //                             </div>
                                //                         </div>

                                //                     </div>

                                //                     <div className='ownpostothercommentdivmain'>
                                //                         <div className='profikellcmnjhhrrddivflxttst'>
                                //                             <div className='profikellcmntimgdivflxttst'>
                                //                                 <div className='commentproofilleimg'>
                                //                                     <img src={cmtprofiletwoimg} alt='...' />
                                //                                 </div>
                                //                                 <p className='commporosttxttnamepp'>John Doe</p>
                                //                             </div>
                                //                             <p className='commporosttxttnamepp'>1 hour ago</p>
                                //                         </div>
                                //                         <p className='commsoprpodttctxppp'>
                                //                             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                //                         </p>
                                //                     </div>

                                //                     <div className='ownpostothercommentdivmain'>
                                //                         <div className='profikellcmnjhhrrddivflxttst'>
                                //                             <div className='profikellcmntimgdivflxttst'>
                                //                                 <div className='commentproofilleimg'>
                                //                                     <img src={cmtprofiletwoimg} alt='...' />
                                //                                 </div>
                                //                                 <p className='commporosttxttnamepp'>John Doe</p>
                                //                             </div>
                                //                             <p className='commporosttxttnamepp'>1 hour ago</p>
                                //                         </div>
                                //                         <p className='commsoprpodttctxppp'>
                                //                             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                //                         </p>
                                //                     </div>
                                //                 </div>
                                //             }
                                //         </div>
                                //     </div>

                                //     <div className='allpostowndmaimndiv'>
                                //         <div className='allpostowndmaimndivallelemnt'>
                                //             <div className='allpostimagedesuigfnatinob'>
                                //                 <div className='allposttslodjjimg'>
                                //                     <img src={logiiaihsui} alt='...' />
                                //                 </div>
                                //                 <div className=''>
                                //                     <h4 className='neeporoalposttsheadr'>Eagle star furniture - Advertising & Marketing</h4>
                                //                     <p className='cmskshhstdiifgysyttxtpop'> <span>Computer </span> |  <sapn> hardware </sapn> | <span>Digital marketing </span></p>
                                //                 </div>
                                //             </div>
                                //             <div className='crosasddoproidikdsjbhidivflx'>
                                //                 <div className='threediostyiicpn'>
                                //                     <i className="fa-solid fa-ellipsis"></i>
                                //                 </div>

                                //                 <div className='crossidovpostimgdiv'>
                                //                     <img src={crosssimgiocn} alt='...' />
                                //                 </div>
                                //             </div>
                                //         </div>
                                //         <div className='postbannettimhdivflxmakflx'>
                                //             <div className='postbanneimmhgdivjakhjimh'>
                                //                 <img src={Photography} alt='...' />
                                //             </div>
                                //             <div className='poosttalouindtdettstctpopdoiv'>
                                //                 <p className='postimagedesrcptoioponttxp'>
                                //                     Neoo Search is a business network and online directory platbusiness with SME's and verified companies, drive more connections, and build new networks and leads. Neoo search business netwrok lets you discover and access a whole new world of business opportunities from
                                //                 </p>
                                //             </div>
                                //         </div>
                                //         <div className=''>
                                //             <p className='htrrstrtdttgsgbtxttppgh'>3 hours ago</p>
                                //         </div>
                                //         <div className="allpostlikecommetsendenqeryudiv">
                                //             <div className="prodctlikecmntenquerydiv">
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={prodlikeicon}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">
                                //                         Like <span>100</span>
                                //                     </p>
                                //                 </div>
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd" onClick={ownposcommentclcik}>
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={commenytstproodiuificon}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">Comment </p>
                                //                 </div>
                                //                 <div className="produucvtsdlikeinmncomnfdivvhjjd">
                                //                     <div className="produucvtsdlikeinmncomnf">
                                //                         <img
                                //                             src={msgiocn}
                                //                             alt=""
                                //                         />
                                //                     </div>
                                //                     <p className="likkskjsbtcomnbvtyoipuxtt">Send Equiry </p>
                                //                 </div>
                                //             </div>
                                //             {ownpostcommetclickmodal &&
                                //                 <div className='ownpostcommentdiv'>
                                //                     <div className='ownpostcommentdivmain'>
                                //                         <div className="cntnctimgdivnamin">
                                //                             <img src={cntnctimg3} alt='...' />
                                //                         </div>

                                //                         <div className='textareadibmainmainbrdr'>
                                //                             <div className='textareadibmain'>
                                //                                 <textarea rows={4} cols={4} className='textareadibmainrerare' placeholder='Write a Comment' />
                                //                             </div>
                                //                             <div className="mesagsendidvmain">
                                //                                 <i class="fa-solid fa-paper-plane"></i>
                                //                             </div>
                                //                         </div>

                                //                     </div>

                                //                     <div className='ownpostothercommentdivmain'>
                                //                         <div className='profikellcmnjhhrrddivflxttst'>
                                //                             <div className='profikellcmntimgdivflxttst'>
                                //                                 <div className='commentproofilleimg'>
                                //                                     <img src={cmtprofiletwoimg} alt='...' />
                                //                                 </div>
                                //                                 <p className='commporosttxttnamepp'>John Doe</p>
                                //                             </div>
                                //                             <p className='commporosttxttnamepp'>1 hour ago</p>
                                //                         </div>
                                //                         <p className='commsoprpodttctxppp'>
                                //                             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                //                         </p>
                                //                     </div>

                                //                     <div className='ownpostothercommentdivmain'>
                                //                         <div className='profikellcmnjhhrrddivflxttst'>
                                //                             <div className='profikellcmntimgdivflxttst'>
                                //                                 <div className='commentproofilleimg'>
                                //                                     <img src={cmtprofiletwoimg} alt='...' />
                                //                                 </div>
                                //                                 <p className='commporosttxttnamepp'>John Doe</p>
                                //                             </div>
                                //                             <p className='commporosttxttnamepp'>1 hour ago</p>
                                //                         </div>
                                //                         <p className='commsoprpodttctxppp'>
                                //                             I just tried this recipe and it was amazing! The instructions were clear and easy to follow, and the end result was delicious. I will definitely be making this again. Thanks for sharing!
                                //                         </p>
                                //                     </div>
                                //                 </div>
                                //             }
                                //         </div>
                                //     </div>
                                // </div>
                            }

                            {
                                AllBusinessPosts?.length > visibleItems?.length &&
                                <div className='lodmrebtnmainndivmaindib'>
                                    <button className='lodmrebtnmainndiv'
                                        // onClick={loadmoresfgbtbnbclokj}
                                        onClick={handleLoadMore}
                                    >
                                        Load More
                                    </button>
                                </div>
                            }

                            {ShareModalOpenClose && (
                                <ProfileShare
                                    whatsApp={postLink}
                                    linkedInLink={postLink}
                                    facebookLink={postLink}
                                    userLink={postLink}
                                    instaLink={postLink}
                                    emailId={postLink}
                                // closeModal={setSharemodal}
                                />
                            )}




                        </div>
                        {handleownpostmodal && <Ownpostpagemodal closemodal={sethandleownpostmodal} data={selectedPostData} />}
                    </>
                )
            }

        </>
    )
}

export default Allpostpage
