import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import dashbordimg from "../../Images/dashbordimg.png";
import analyticsdashbordimage from "../../Images/analyticsdashbordimage.png";
import Rewards from "../../Images/Rewards.png";
import referernimg from "../../Images/referernimg.png"
import statuslogo from '../../Images/Vector (20).png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import pageprofile from '../../Images/1logo 3.png'
import tick from '../../Images/Vector (21).png'
import womenphoto from '../../Images/Clip path group.png'
import loading from '../../Images/Group 2.png'
import suslogo from '../../Images/suspend icon 1.png'
import { useSelector } from 'react-redux';
import moment from 'moment';
const MemberStatus = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    const colordata = useContext(Colorcontext);
    const [pageUnderReview, setPageUnderReview] = useState(false)
    const [pageSuspended, setPageSuspended] = useState(!UserProfileID?.businessStatus)
    const [customer, setcustomer] = useState(false)
    const [profile, setprofile] = useState(false)
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    const location = useLocation()
    const navigate = useNavigate()

    console.log("UserProfileID32", UserProfileID);

    return (
        <section className='membermastersection'>
            <div className="cust_container">
                <div className='membermastersectionbg'>
                    <div className='membermastersectionbgtop'>
                        <div className='dashbordimgdtioodicflx'>
                            <div className='dashbordimgdivimg'>
                                <img src={dashbordimg} alt='...' />
                            </div>
                            <div className=''>
                                <p className='dahshnlisjhtxtpp'>Status</p>
                            </div>
                        </div>
                    </div>
                    <div className='membermastersectionbgbottom'>
                        <div className='membermastersectionbgbottomdivflx'>

                            <div className='embermastersectionbgbobgmai'>
                                <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/status'))} style={location.pathname == '/status' ? { background: "white", border: '1px solid black', borderRadius: '8px' } : { backgroundColor: 'rgb(244, 124, 188)' }}>
                                    <div className='analyticsshsimage'>
                                        <img src={statuslogo} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='alkantsrepposttxtpp'>View Status <br />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='embermastersectionbgbobgmai'>
                                <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "rgb(202, 207, 209)" }}>
                                    <div className='analyticsshsimage'>
                                        <img src={analyticsdashbordimage} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='alkantsrepposttxtpp'>Analytics <br />
                                            Report</p>
                                    </div>
                                </div>
                            </div> */}

                            {
                                (UserProfileID.memberStatus && UserProfileID.businessStatus) &&
                                <>
                                    <div className='embermastersectionbgbobgmai'>
                                        <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#52DCFB" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={analyticsdashbordimage} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Analytics <br />
                                                    Report</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='embermastersectionbgbobgmai'

                                    >
                                        <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#D7A3FF" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={referernimg} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Refer and earn <br />
                                                    Dashboard</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='embermastersectionbgbobgmai' style={{ cursor: "pointer" }}>
                                        <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/reward'))} style={{ backgroundColor: "#71D026" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={Rewards} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Rewards <br />
                                                    Dashboard
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (!UserProfileID.memberStatus && UserProfileID.businessStatus) &&
                                <>
                                    <div className='embermastersectionbgbobgmai'>
                                        <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#52DCFB" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={analyticsdashbordimage} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Analytics <br />
                                                    Report</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }



                        </div>




                        {/* NEW CONTENT ADDED FOR THE STATUS PAGE */}


                        <div className='page_status_div_main'>
                            <p className='page_status_para'>Page Status</p>
                            <div className='page_status_details'>
                                <div className='page_name_profile_wrapper_div'>
                                    <div className='page_profile_img prflimg'>
                                        <img src={UserProfileID?.businessProfilePic || pageprofile} />
                                    </div>
                                    <p className='page_name'>{UserProfileID?.companyName || ""}</p>
                                </div>

                                {
                                    (!pageSuspended && !pageUnderReview) &&
                                    <div className='page_live_status_div'>
                                        <p className='live_para'>Live</p>
                                        <div className='live_green_div'>
                                            <img src={tick} />
                                        </div>
                                    </div>
                                }

                                {/* loading_div_design Starts Here  */}
                                {pageUnderReview &&
                                    <div className='page_live_status_div'>
                                        <p className='live_para'>Under Review</p>
                                        <div className='live_green_div2'>
                                            <img src={loading} />
                                        </div>
                                    </div>}
                                {/* Loading Design ends */}
                                {/* suspended page design here */}
                                {pageSuspended && <div className='page_live_status_div'>
                                    <p className='live_para'>Page suspended</p>
                                    <div className='live_green_div2'>
                                        <img src={suslogo} />
                                    </div>
                                </div>}

                                {/* Suspended page design ends here */}


                            </div>

                            <div className='page_edit_delete_wrapper'>
                                <p className='page_action_para'><Link
                                // to={`/businesspage/${UserProfileID?.companyName.toLowerCase().replace(/\s+/g, "_")}`}
                                >Edit page</Link></p>
                                <p className='page_action_para'><Link>Delete page</Link></p>
                                <p className='page_action_para'><Link>View report</Link></p>



                                {/* Contact Customer Link design Starts here */}
                                {customer && <Link style={{
                                    textDecoration: 'underline',
                                    fontSize: '12px',
                                    marginTop: '-10px'
                                }}>Contact customer support</Link>}

                                {/* Contact Customer Link design ends here */}


                            </div>
                        </div>

                        {/* <div className='border_div'></div> */}


                        {/* PRODUCT & SERVICES DESIGN STARTS FROM HERE */}

                        {/* CLASSNAME FOR TRANSPARENT BACKGROUND = 'bg_white' */}
                        <div className='product_services_div_wrapper '>

                            <div className='product_head_div'>
                                <h2>Product & Services</h2>
                                <h2>Items</h2>
                                <h2>Renewal Date</h2>
                            </div>
                            <div className='product_head_div2'>
                                <h2>{UserProfileID?.SubscriptionData?.plan} Plan <br /> <span>
                                    {UserProfileID?.SubscriptionData?.plan.toLowerCase().trim() === "gold" ? "Premium listing" : UserProfileID?.SubscriptionData?.plan.toLowerCase().trim() === "silver" ? "Standard listing" : "Basic listing"}
                                </span></h2>

                                <h2><span>{UserProfileID?.SubscriptionData?.listing?.length > 0 && "Business listing"}  <br />
                                    {UserProfileID?.SubscriptionData?.businessPack?.length > 0 && "Business pack"}
                                </span></h2>

                                <h2>Expires<br /> <span>
                                    {UserProfileID?.subscriptionStartDate ? moment(UserProfileID?.subscriptionStartDate).add(2, "years").format('MM/DD/YYYY') : moment(UserProfileID?.updatedOn).add(2, "years").format('MM/DD/YYYY')}
                                </span></h2>
                            </div>
                        </div>


                        <p className='text-right renew_para'><Link >Renew Now</Link></p>
                        {/* Dear CLient Design Starts Here */}
                        {pageUnderReview && <div className='dear_client_message_div'>
                            <p>Dear esteemed client,</p>
                            <p>We appreciate your decision to join the Neoo search business directory.</p>
                            <p>Your company page is being evaluated; expect it online within 1-3 days.</p>
                            <div className='client_help_div'>
                                <p>If need any help</p>
                                <p>Call/whatsapp 04444</p>
                                <p style={{
                                    marginTop: '-5px'
                                }}>Email: support@neoosearch.com</p>
                            </div>
                        </div>}

                        {
                            UserProfileID.memberStatus &&
                            <div className='page_status_div_main'>
                                <p className='page_status_para'>Profile Status</p>
                                <div className='page_status_details'>
                                    <div className='page_name_profile_wrapper_div'>
                                        <div className='page_profile_img prflimg'>
                                            <img src={UserProfileID?.profilePhoto || womenphoto} />
                                        </div>
                                        <div>
                                            <p className='page_owner_name'>{UserProfileID?.personName}</p>
                                            <p className='page_name2'>{UserProfileID?.companyName}</p>
                                        </div>

                                    </div>
                                    {
                                        UserProfileID?.status === "Active" &&
                                        <div className='page_live_status_div'>
                                            <p className='live_para'>Live</p>
                                            <div className='live_green_div'>
                                                <img src={tick} />
                                            </div>
                                        </div>
                                    }


                                    {/* loadind_div_design Starts Here  */}
                                    {/* {pageUnderReview && <div className='page_live_status_div'>
                                    <p className='live_para'>Under Review</p>
                                    <div className='live_green_div'>
                                        <img src={loading} />
                                    </div>
                                </div>} */}
                                    {/* Loading Design ends */}

                                    {/* suspended page design here */}

                                    {UserProfileID?.status === "Pending" && <div className='page_live_status_div'>
                                        <p className='live_para'>Page suspended</p>
                                        <div className='live_green_div2'>
                                            <img src={suslogo} />
                                        </div>
                                    </div>}

                                    {/* Suspended page design ends here */}

                                </div>

                                <div className='page_edit_delete_wrapper'>
                                    <p className='page_action_para'><Link>Edit profile</Link></p>
                                    <p className='page_action_para'><Link>Delete profile</Link></p>
                                    <p className='page_action_para'><Link>View report</Link></p>

                                    {/* Contact Customer Link design Starts here */}
                                    {customer && <Link style={{
                                        textDecoration: 'underline',
                                        fontSize: '12px',
                                        marginTop: '-10px'
                                    }}>Contact customer support</Link>}

                                    {/* Contact Customer Link design ends here */}
                                </div>

                            </div>
                        }


                        {/* Create Profile Button Design Starts here */}

                        {!UserProfileID.memberStatus && <>
                            <div className='d-flex justify-content-end align-items-center'>
                                <Link to="/upgradeplan" className='create_profile_btn'>Upgrade plan</Link>
                            </div>
                        </>}
                        {/* Create Profile Button Design Starts Ends */}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default MemberStatus
