import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import HttpClient from '../../utils/HttpClient';

//get All Posts
export const fetchAllNotifications = createAsyncThunk('Data/AllNotification', async () => {
    const response = await HttpClient.requestData("all-notification", "GET", {});
    console.log("FF15",response);
    
    return response.data;
});


// Create the Data slice
const notificationSlice = createSlice({
    name: 'Data',
    initialState: {
        AllNotifications: [],
        status: '',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            // Fetching Notification Data
            .addCase(fetchAllNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllNotifications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.AllNotifications = action?.payload?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                // .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            })
            .addCase(fetchAllNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action?.error.message;
            })
    },
});

export const { } = notificationSlice.actions
export default notificationSlice.reducer

// Export the asynchronous thunks for use in components or other parts of your application