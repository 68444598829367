import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Updatepostmoda from '../../Modal/Updatepostmoda'
import { useSelector } from 'react-redux';
import BusinessPostModal from '../../Modal/BusinessPostModal';
import iconenquiry from "../../Images/icon-enquiry.png";
import BlmEnquiryModal from '../../Modal/BlmEnquiryModal';

const BlmEnquiry = (isActive) => {
    const { UserProfileID } = useSelector((state) => state.app);


    const [ownpostupdatemodal, setownpostupdatemodal] = useState(false)
    const ownnsposyymodal = () => {
        setownpostupdatemodal(!ownpostupdatemodal)
    }
    // console.log("LINE10X", isActive);

    return (
        <>
            <div className='bmlmmaposttaddddib'>
                <div className='bmlmmaposttaddddibdivmain'>
                    {/* <p className='poiosyyattatxtpp'>
                        Post your latest product or service
                        to connect your customers.
                    </p> */}
                    <div className='poisyybtnmainlibkldivflx'>
                        {/* <button
                            className='poisyybtnmain'
                            onClick={ownnsposyymodal}
                            disabled={!UserProfileID?.businessStatus}
                        >
                            Send Enquiry
                            <i className="fa-solid fa-plus"></i>
                        </button> */}
                        <button className="direxrrcttstbtn"
                        style={{
                            margin:"0 auto"
                        }}
                        onClick={ownnsposyymodal}
                        >
                            <img src={iconenquiry} alt="arrow icon"
                            style={{
                                marginRight:"5px"
                            }}
                            />
                            Send Enquiry
                        </button>

                    </div>
                </div>
            </div>
            {/* {ownpostupdatemodal && <Updatepostmoda closemodal={setownpostupdatemodal} />} */}
            {/* {ownpostupdatemodal && <BusinessPostModal closemodal={setownpostupdatemodal} />} */}
            
            {ownpostupdatemodal && <BlmEnquiryModal closemodal={setownpostupdatemodal}  />}
        </>
    )
}

export default BlmEnquiry
