import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../utils/HttpClient';

//get Page enquiries'
export const fetchPageEnquiries = createAsyncThunk('Data/PageEnquiries', async () => {
    const response = await HttpClient.requestData("all-business-queries", "GET", {})
    console.log("HDHD7", response)
    return response.data;
});

//get Post enquiries'
export const fetchPostEnquiries = createAsyncThunk('Data/PostEnquiries', async () => {
    const response = await HttpClient.requestData("all-post-queries", "GET", {});
    console.log("HDHD14", response)
    return response.data;
});


// Create the Data slice
const enquirySlice = createSlice({
    name: 'Data',
    initialState: {
        PageEnquiries: [],
        PostEnquiries: [],
        status: '',
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            // Fetching My Business Page Enquiry Data
            .addCase(fetchPageEnquiries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPageEnquiries.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.PageEnquiries = action?.payload.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
            })
            .addCase(fetchPageEnquiries.rejected, (state, action) => {
                state.status = 'failed';
                state.PageEnquiries = null;
                state.error = action?.error.message;
            })
            // Fetching My Business Posts Enquiry Data
            .addCase(fetchPostEnquiries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPostEnquiries.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.PostEnquiries = action?.payload
                .filter((item)=>{return item.PostData})
                .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                
            })
            .addCase(fetchPostEnquiries.rejected, (state, action) => {
                state.status = 'failed';
                state.PostEnquiries = null;
                state.error = action?.error.message;
            })
    },
});

export const { } = enquirySlice.actions
export default enquirySlice.reducer

// Export the asynchronous thunks for use in components or other parts of your application