import React, { useContext, useEffect, useState } from 'react'
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import dashbordimg from "../../Images/dashbordimg.png";
import analyticsdashbordimage from "../../Images/analyticsdashbordimage.png";
import Rewards from "../../Images/Rewards.png";
import referernimg from "../../Images/referernimg.png"
import statuslogo from '../../Images/Vector (20).png'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Membermasterdashbord = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    const navigate = useNavigate()
    return (
        <section className='membermastersection'>
            <div className="cust_container">
                <div className='membermastersectionbg'>
                    <div className='membermastersectionbgtop'>
                        <div className='dashbordimgdtioodicflx'>
                            <div className='dashbordimgdivimg'>
                                <img src={dashbordimg} alt='...' />
                            </div>
                            <div className=''>
                                <p className='dahshnlisjhtxtpp'>Dashboard Links</p>
                            </div>
                        </div>
                    </div>
                    <div className='membermastersectionbgbottom'>
                        <div className='membermastersectionbgbottomdivflx'>

                            <div className='embermastersectionbgbobgmai' style={{ cursor: "pointer" }}>
                                <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/status'))} style={{ backgroundColor: "rgb(244, 124, 188)" }}>
                                    <div className='analyticsshsimage'>
                                        <img src={statuslogo} alt='...' />
                                    </div>
                                    <div className=''>
                                        <p className='alkantsrepposttxtpp'>View Status <br />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {
                                (UserProfileID?.memberStatus && UserProfileID?.businessStatus) &&
                                <>
                                    <div className='embermastersectionbgbobgmai' style={{ cursor: "pointer" }}>
                                        <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/analytics'))} style={{ backgroundColor: "#52DCFB" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={analyticsdashbordimage} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Analytics <br />
                                                    Report</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='embermastersectionbgbobgmai'>
                                        <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#D7A3FF" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={referernimg} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Refer and earn <br />
                                                    Dashboard</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='embermastersectionbgbobgmai' style={{ cursor: "pointer" }}>
                                        <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/reward'))} style={{ backgroundColor: "#71D026" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={Rewards} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Rewards <br />
                                                    Dashboard
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                (!UserProfileID?.memberStatus && UserProfileID?.businessStatus) &&
                                <>
                                <div className='embermastersectionbgbobgmai' style={{ cursor: "pointer" }}>
                                        <div className='embermastersectionbgbobgmainnbg' onClick={(() => navigate('/analytics'))} style={{ backgroundColor: "#52DCFB" }}>
                                            <div className='analyticsshsimage'>
                                                <img src={analyticsdashbordimage} alt='...' />
                                            </div>
                                            <div className=''>
                                                <p className='alkantsrepposttxtpp'>Analytics <br />
                                                    Report</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }




                            {/* <div className='embermastersectionbgbobgmai'>
                                <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#D9D9D9" }}>
                                  
                                    <div className=''>
                                        <p className='alkantsrepposttxtppqueiytettcpp'>Quiz contest <br/>
                                        Dashboard</p>
                                    </div>
                                </div>
                            </div>
                            <div className='embermastersectionbgbobgmai'>
                                <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#D9D9D9" }}>
                                  
                                    <div className=''>
                                        <p className='alkantsrepposttxtppqueiytettcpp'>Quiz contest <br/>
                                        Dashboard</p>
                                    </div>
                                </div>
                            </div>
                            <div className='embermastersectionbgbobgmai'>
                                <div className='embermastersectionbgbobgmainnbg' style={{ backgroundColor: "#D9D9D9" }}>
                                  
                                    <div className=''>
                                        <p className='alkantsrepposttxtppqueiytettcpp'>Quiz contest  <br/>
                                        Dashboard</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Membermasterdashbord
