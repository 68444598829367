import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Colorcontext } from '../../Component/Colorcontext/ColorContext';
import img from '../../Images/analyticsdashbordimage.png'
import img2 from '../../Images/Vector (26).png'
import img3 from '../../Images/Vector (27).png'
import { NavLink } from 'react-router-dom'
import arrowup from '../../Images/expand_less.png'
import img5 from '../../Images/Group (8).png'
import img6 from '../../Images/Vector (28).png'
import img7 from '../../Images/Vector (29).png'
import img8 from '../../Images/calendar.png'
import arrowup2 from '../../Images/arrow-up.png'
import arrowdown from '../../Images/arrow-down.png'
import logo from '../../Images/Neoo logo PWA 1.png'
import logo2 from '../../Images/1logo 2.png'
import logo3 from '../../Images/4logo 2.png'
import user from '../../Images/user.png'
const Analytics = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    const [dropdown, setdropdown] = useState(false)
    const [dropdown2, setdropdown2] = useState(false)
    const [showFilter,setShowFilter]=useState(false)
    const colordata = useContext(Colorcontext);
    useEffect(() => {

        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    }, []);
    return (
        <>
            <section className='membermastersection'>
                <div className="cust_container">
                    <div className='membermastersectionbg member_section_new_class' style={{

                        background: 'transparent',
                    }}>
                        <div className='left_analtics_div_wrapper'>
                            <div className='left_analtics_container'>
                                <div className='left_analtics_logo'>
                                    <div className='analytics_img_div'>
                                        <img src={img} />

                                    </div>
                                    <p>Analytics</p>
                                </div>
                                <div className='nav_link_wrapper'>
                                    <NavLink to={'/analytics'} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img2} />
                                        </div>
                                        <p>Overview</p>
                                    </NavLink>
                                </div>
                                <div className='nav_link_wrapper'>
                                    <div onClick={(() => setdropdown(!dropdown))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>BLM page</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>
                                    {dropdown && <div className='blm_page_dropdown_div'>
                                        <p>Followers</p>
                                        <p>Public</p>
                                    </div>}

                                    <div onClick={(() => setdropdown2(!dropdown2))} className='left_analtics_links_wrapper'>
                                        <div className='left_analtics_link_img'>
                                            <img src={img3} />
                                        </div>
                                        <p>Profile</p>

                                        <img src={arrowup} style={{
                                            width: '22px',
                                            height: '22px',
                                            marginTop: '7px'
                                        }} />
                                    </div>

                                    {dropdown2 && <div className='blm_page_dropdown_div'>
                                        <p>Members</p>
                                        <p>Non Members</p>
                                    </div>}
                                </div>
                            </div>
                            <div className='export_div_wrapper'>
                                <div className='export_div'>
                                    <div className='export_img'>
                                        <img src={img5} />
                                    </div>
                                    <p>Export</p>
                                </div>
                                <div className='lock_img_div'>
                                    <img src={img6} />
                                </div>
                            </div>
                        </div>
                        <div className='right_analtics_div'>
                            <div className='right_analtics_div_container'>
                                <div className='overview_wrapper'>
                                    <p>Overview</p>
                                    {
                                        showFilter &&
                                        <div className='filter_box_wrapper'>
                                            <div className='filter_box'>
                                                <img src={img7} />
                                                <p>2</p>
                                            </div>
                                            <div className='filter_box'>
                                                <img src={img8} />
                                                <span>Jan 1, 2024 - Dec 31, 2024</span>
                                            </div>
                                        </div>
                                    }


                                </div>
                                <div className='enquires_wrapper'>
                                    <div className='enquire_div_1'>
                                        <p>Page Enquires</p>
                                        <h3>420K</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Post Enquires</p>
                                        <h3>980K</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Page Hits</p>
                                        <h3>230K</h3>
                                    </div>
                                    <div className='enquire_div_1'>
                                        <p>Page Likes</p>
                                        <h3>420K</h3>
                                    </div>
                                </div>
                                <div className='right_box_wrapper'>
                                    <div className='right_box_div'>
                                        <p>Rating</p>
                                        <div className='star_wrapper'>
                                            <span>2.0</span>
                                            <i class="fa-solid fa-star text-warning"></i>
                                            <i class="fa-solid fa-star text-warning"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                        </div>
                                    </div>

                                    <div className='right_box_div'>
                                        <p>Followers</p>
                                        <div className='star_wrapper2'>
                                            <span>70K</span>
                                            {/* <h6>
                                                <img src={arrowup2} />
                                                26%
                                            </h6> */}
                                        </div>
                                    </div>

                                    <div className='right_box_div'>
                                        <p>Impressions</p>
                                        <div className='star_wrapper3'>
                                            <span>10K</span>
                                            {/* <h6>
                                                <img src={arrowdown} />
                                                4%
                                            </h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='right_analtics_table_wrapper'>
                                <div className='all_actives_wrapper'>
                                    <h2>All Activities</h2>
                                    <div className='select_boxes'>
                                        <select>
                                            <option value={'BlM Page'}>BLM Page</option>
                                            <option value={'BlM Page'}>BLM Page</option>
                                            <option value={'BlM Page'}>BLM Page</option>
                                            <option value={'BlM Page'}>BLM Page</option>
                                            <option value={'BlM Page'}>BLM Page</option>
                                            <option value={'BlM Page'}>BLM Page</option>
                                        </select>

                                        <select>
                                            <option value={'Followers'}>Followers</option>
                                            <option value={'Followers'}>Followers</option>
                                            <option value={'Followers'}>Followers</option>
                                            <option value={'Followers'}>Followers</option>
                                            <option value={'Followers'}>Followers</option>
                                            <option value={'Followers'}>Followers</option>
                                        </select>

                                        <select>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                            <option value={'Last 7 Days'}>Last 7 Days</option>
                                        </select>
                                    </div>


                                </div>
                                <div className='right_table_content_wrapper'>
                                    <table>
                                        <tbody>
                                            {[logo, logo2, logo3, logo, logo2, logo3, logo, logo2, logo3, logo, logo2, logo3, logo, logo2, logo3, logo, logo2, logo3].map((e, i) => {
                                                return (
                                                    <>
                                                        <tr style={{
                                                            borderBottom: ' 1px solid rgba(195, 195, 195, 1)'
                                                        }}>
                                                            <td className='table_data_1'>
                                                                <div className='company_logo_details_Wrappper'>
                                                                    <div className='logo_img656'>
                                                                        <img src={e} />
                                                                    </div>
                                                                    <div className='logo_details656'>
                                                                        <p>Neoo Search FZE</p>
                                                                        <span>Advertising & Marketing</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='table_data_2'>
                                                                Started following You
                                                            </td>
                                                            <td className='table_data_3'>
                                                                15 min ago
                                                            </td>
                                                            <td className='table_data_4'>
                                                                <div>
                                                                    <img src={user} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Analytics
